import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  React, useEffect, useMemo, useState,
} from 'react';

import mapboxgl from 'mapbox-gl';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { IconArrowDown } from '../icons';
import {
  devicesActions, eventModalActions, eventsSideBarActions, sidebarActions,
} from '../../../store';

import LoaderContainer from '../LoaderContainer';
import CustomTabs from '../CustomTabs';
import CustomTab from '../CustomTab';
import TabPanel from '../TabPanel';
import EventList from './Components/EventList';
import DialogPageContainer from '../DialogPageContainer';
import api from '../../../Http';
import { map } from '../../../map/core/MapView';
import DesktopHeader from '../DesktopHeader';
import EventCount from '../EventCount';
import MobileHeader from '../MobileHeader';
import GenericSelect from '../GenericSelect/GenericSelect';

const EventsCard = ({ carInfo, getCarInfo }) => {
  const showEventModal = useSelector((state) => state.eventModal.isOpen);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const originEventCard = useSelector((state) => state.eventsSideBar.from);
  const userId = useSelector((state) => state.session.user.id);
  const [userEvents, setUserEvents] = useState(null);
  const [carEventsResolved, setCarEventsResolved] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(0);

  const orders = [
    {
      label: 'Recentes',
      value: 0,
    },
    {
      label: 'Resolvidos',
      value: 1,
    },
  ];

  const dispatch = useDispatch();
  const carId = carInfo?.id;

  const carEvents = useMemo(() => {
    if (originEventCard === 'carCard') {
      return carEventsResolved ?? { Baixo: [], Medio: [], Critico: [] };
    }
    return userEvents ?? { Baixo: [], Medio: [], Critico: [] };
  }, [carInfo, originEventCard, userEvents, carEventsResolved]);

  const getUserEvents = async () => {
    try {
      const response = await api.get('/api/events/search', {
        params: { userId, ignored: false, resolved: selectedOrder === 1 },
      });
      setUserEvents(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getCarEvents = async () => {
    try {
      const response = await api.get('api/events/search', {
        params: { deviceId: carId, ignored: false, resolved: selectedOrder === 1, telemetry: false },
      });
      setCarEventsResolved(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (originEventCard === 'carCard') {
      getCarEvents();
    }
    if (originEventCard === 'sideBar') {
      getUserEvents();
    }
  }, [originEventCard, selectedOrder, carId]);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const reload = () => {
    if (originEventCard === 'sideBar') {
      getUserEvents();
      return;
    }
    getCarInfo(carId);
  };

  useEffect(() => {
    if (!showEventModal && originEventCard === 'sideBar') {
      reload();
    }
  }, [showEventModal]);

  const refreshDevice = async (deviceId) => {
    const response = await api.get(`/api/devices?id=${deviceId}&events=true`);
    if (response.status === 200) {
      dispatch(devicesActions.singleRefresh(response?.data[0]));
    }
  };

  const ignore = async (event) => {
    const response = await api.put(`api/events/ignore?id=${event.eventId}`);
    if (response.status === 200) {
      reload();
      refreshDevice(event.deviceId);
    }
  };

  const verify = async (event) => {
    const response = await fetch(`/api/positions?id=${event.positionId}`);
    if (response.ok) {
      const nPos = await response.json();

      const coordinates = [nPos[0].longitude, nPos[0].latitude];
      dispatch(sidebarActions.close());
      dispatch(eventsSideBarActions.close());
      map.easeTo({
        center: coordinates,
        zoom: 17,
      });

      const marker = new mapboxgl.Marker()
        .setLngLat(coordinates)
        .addTo(map);

      event.marker = marker;

      dispatch(eventModalActions.open(event));
      dispatch(eventsSideBarActions.close());
    }
  };

  // eslint-disable-next-line no-unused-vars

  return (
    <DialogPageContainer zIndex={5}>
      {isLoading && (
        <LoaderContainer>
          <CircularProgress color="inherit" />
        </LoaderContainer>
      )}

      <Box position="relative" width="100%" padding="12px 16px">
        {desktop && <DesktopHeader title={originEventCard === 'carCard' ? 'Todos eventos do veículo' : 'Eventos'} onClose={() => dispatch(eventsSideBarActions.close())} />}
        {!desktop && <MobileHeader goBack={originEventCard === 'carCard'} title={originEventCard === 'carCard' ? 'Todos eventos do veículo' : 'Eventos'} onClose={() => dispatch(eventsSideBarActions.close())} />}
      </Box>

      <Box width="100%">
        <Divider orientation="horizontal" />
      </Box>

      <Box position="relative" width="100%" height="calc(100vh - 256px)">
        <Box position="relative" padding="24px">

          <Box position="relative">
            <CustomTabs
              value={currentTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <CustomTab desktop={desktop.toString()} value={0} label="Baixo" {...a11yProps(0)} />
              <EventCount severity="low" sx={{ position: 'absolute', left: '23%', top: '16px', zIndex: 2 }}>
                <Typography variant="event-count">
                  {carEvents?.Baixo?.length}
                </Typography>
              </EventCount>

              <CustomTab desktop={desktop.toString()} value={1} label="Médio" {...a11yProps(1)} />
              <EventCount severity="medium" sx={{ position: 'absolute', left: '56%' }}>
                <Typography variant="event-count">
                  {carEvents?.Medio?.length}
                </Typography>
              </EventCount>

              <CustomTab desktop={desktop.toString()} value={2} label="Crítico" {...a11yProps(2)} />
              <EventCount severity="critical" sx={{ position: 'absolute', right: '13px' }}>
                <Typography variant="event-count">
                  {carEvents?.Critico?.length}
                </Typography>
              </EventCount>
            </CustomTabs>
          </Box>
        </Box>

        <Box padding="0 32px 0 32px" mb="16px" width="100%" display="flex" justifyContent="flex-end" alignItems="flex-end">
          <GenericSelect options={orders} onSelect={setSelectedOrder} value={selectedOrder}>
            <Stack gap="12px" alignItems="center" direction="row">
              <Typography
                sx={{
                  color: '#333',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '21px',
                }}
                variant="base"
              >
                Ordenar
              </Typography>
              <IconArrowDown />
            </Stack>
          </GenericSelect>
        </Box>

        {carEvents && (
        <>
          <TabPanel noPadding index={0} value={currentTab}>
            <EventList verify={verify} ignore={ignore} list={carEvents?.Baixo} />
          </TabPanel>
          <TabPanel noPadding index={1} value={currentTab}>
            <EventList verify={verify} ignore={ignore} list={carEvents?.Medio} />
          </TabPanel>
          <TabPanel noPadding index={2} value={currentTab}>
            <EventList verify={verify} ignore={ignore} list={carEvents?.Critico} />
          </TabPanel>
        </>
        )}

      </Box>

    </DialogPageContainer>
  );
};

export default EventsCard;
