import {
  Box, Dialog, Divider, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import React from 'react';
import { IconCheckReport, IconCloseDialog } from '../../icons';
import RenderIcon from './RenderIcons';

const ActionsDialog = ({ modalInfo, setModalInfo, sucess, setSucess }) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Dialog
      open={!!modalInfo}
      onClose={() => {
        setModalInfo(null);
        setSucess(false);
      }}
    >
      {modalInfo && (
        <Paper sx={{
          width: desktop ? '348px' : 'auto',
          height: desktop ? '223px' : 'auto',
        }}
        >
          <Box sx={{
            padding: '24px 0px 12px 0px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
          >

            <IconButton
              onClick={() => setModalInfo(null)}
              sx={{
                position: 'absolute',
                top: '8px',
                right: '8px',
              }}
            >
              <IconCloseDialog />
            </IconButton>

            <Box sx={{
              width: 'minContent',
              height: '44px',
              borderRadius: '40px',
              background: '#F5EBFF',
              padding: '24px 12px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '12px',
            }}
            >
              <RenderIcon type={modalInfo.icon} />

              <Typography sx={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontWeight: '600',
                lineHeight: '19px',
                letterSpacing: '0em',
                textAlign: 'center',
                color: '#8000FF',
              }}
              >
                {modalInfo.text}
                {' '}
                Veiculo
              </Typography>
            </Box>

            <Box padding="16px 12px 0px 12px" textAlign="center">
              <Typography variant="dialog-text">
                {'Confirmar '}
              </Typography>
              <Typography color="#333" variant="dialog-text">
                o envio desta
              </Typography>
              <Typography variant="dialog-text">
                {' ação'}
              </Typography>
              <br />

              <Typography color="#333" variant="dialog-text">
                para o veículo selecionado?
              </Typography>
            </Box>

            <Box paddingTop="24px" paddingBottom="16px" width="100%">
              <Divider orientation="horizontal" />
            </Box>

            <Stack width="100%" padding="0px 24px" gap="16px" direction={desktop ? 'row' : 'column'}>
              {sucess ? (
                <IconButton
                  sx={{
                    width: '100%',
                    height: '34px',
                    borderRadius: '50px',
                    padding: 'none',
                    background: '#27AE60',

                    '&:hover': {
                      background: '#27AE60',
                    },
                  }}
                  variant="base"
                  onClick={() => {
                    setModalInfo(null);
                    setSucess(false);
                  }}
                >
                  <Typography variant="button-text">
                    <IconCheckReport />
                  </Typography>
                </IconButton>
              )

                : (
                  <>
                    <IconButton
                      sx={{
                        width: desktop ? '143px' : '100%',
                        height: '34px',
                        borderRadius: '50px',
                        padding: 'none',

                      }}
                      variant="base"
                      onClick={() => {
                        modalInfo.callback();
                      }}
                    >
                      <Typography variant="button-text">
                        Sim
                      </Typography>
                    </IconButton>
                    <IconButton
                      sx={{
                        width: desktop ? '143px' : '100%',
                        height: '34px',
                        borderRadius: '50px',
                        padding: 'none',
                      }}
                      variant="base"
                      onClick={() => {
                        setModalInfo(null);
                      }}
                    >
                      <Typography variant="button-text">
                        Não
                      </Typography>
                    </IconButton>
                  </>
                )}
            </Stack>

          </Box>
        </Paper>
      )}
    </Dialog>
  );
};

export default ActionsDialog;
