import {
  Box, Button, Stack, Typography, useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Car } from '../icons';

const ReportMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const isCurrentRoute = (route) => location.pathname.includes(route);

  const navigatoTo = (route) => navigate(route);

  return (

    <Stack padding="24px 32px" direction="row" alignItems="center" gap="37px" sx={desktop ? {} : { maxWidth: '100vw', overflow: 'auto' }}>
      <Stack direction="row" alignItems="center" gap="14px">
        <Box sx={{ background: '#F7EEFF', borderRadius: '150%', width: '48px', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Car />
        </Box>
        <Typography variant="nav-legend" fontSize="16px">
          Relatórios
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" gap="14px">
        <Button variant="route-menu" onClick={() => navigatoTo('/reports/route')} className={isCurrentRoute('route') ? 'selected' : ''}>
          Rota
        </Button>
        <Button variant="route-menu" onClick={() => navigatoTo('/reports/event')} className={isCurrentRoute('event') ? 'selected' : ''}>
          Eventos
        </Button>
        <Button variant="route-menu" onClick={() => navigatoTo('/reports/trip')} className={isCurrentRoute('trip') ? 'selected' : ''}>
          Viagens
        </Button>
        <Button variant="route-menu" onClick={() => navigatoTo('/reports/stop')} className={isCurrentRoute('stop') ? 'selected' : ''}>
          Paradas
        </Button>
        <Button variant="route-menu" onClick={() => navigatoTo('/reports/summary')} className={isCurrentRoute('summary') ? 'selected' : ''}>
          Resumo
        </Button>
        <Button variant="route-menu" onClick={() => navigatoTo('/reports/chart')} className={isCurrentRoute('chart') ? 'selected' : ''}>
          Gráfico
        </Button>
      </Stack>
    </Stack>
  );
};

export default ReportMenu;
