import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'eventsSideBar',
  initialState: {
    isOpen: false,
    from: null,
  },
  reducers: {
    open(state, action) {
      state.isOpen = true;
      state.from = action.payload;
    },
    close(state) {
      state.isOpen = false;
      state.from = null;
    },
  },
});

export { actions as eventsSideBarActions };
export { reducer as eventsSideBarReducer };
