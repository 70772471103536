import {
  Box, Typography,
} from '@mui/material';
import React from 'react';
import DriverItem from './DriverItem';

const DriversTab = ({ drivers }) => {
  const currentDriver = drivers[drivers.length - 1];

  return (
    <Box padding="0 32px">
      {currentDriver && (
        <>
          <DriverItem currentDriver={currentDriver} />

          <Typography m="32px 0 16px">
            Outros condutores deste veiculo
          </Typography>
        </>
      )}

      {/* temporariamente inativado
      <Stack gap="16px">
        {drivers && drivers.map((driver) => <OtherDriverItem key={driver.id} driver={driver} />)}
      </Stack>
      */}

    </Box>
  );
};

export default DriversTab;
