import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';
import { useDispatch } from 'react-redux';
import { eventsSideBarActions, sidebarActions } from '../store';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#8000FF',
    color: '#fff',
    borderRadius: '38px',
    boxShadow: '0px 8px 12px 0px rgba(128, 0, 255, 0.24)',

    display: 'inline-flex',
    padding: '4px 16px 5px 16px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const SidebarItem = ({ selected, handleSelection, name, children, setKeyword = () => null }) => {
  const dispatch = useDispatch();

  const SidebarItemEnum = {
    map: 'Mapa',
    events: 'Eventos',
    reports: 'Relatórios',
    settings: 'Configurações',
  };

  return (
    <HtmlTooltip
      placement="right"
      title={(
        <Typography variant="tooltip">
          {SidebarItemEnum[name]}
        </Typography>
            )}
    >
      <IconButton
        variant="sidebar"
        className={selected === name ? 'selected' : ''}
        onClick={(ev) => {
          handleSelection(ev, name);
          if (name === 'reports') {
            dispatch(sidebarActions.close());
            dispatch(eventsSideBarActions.close());
            setKeyword('');
          }
        }}
      >
        {children}
      </IconButton>
    </HtmlTooltip>
  );
};

export default SidebarItem;
