import { IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { Car } from './icons';

const DesktopHeader = ({ title, onClose, altIcon }) => (
  <Stack
    direction="row"
    sx={{
      width: '100%',
    }}
  >
    <Stack gap="14px" width="100%" direction="row" alignItems="center">
      <div
        style={{
          width: '48px',
          background: '#F7EEFF',
          height: '48px',
          borderRadius: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {altIcon || <Car />}

      </div>
      <Typography
        sx={{
          color: '#121212',
          fontSize: '16px',
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '24px',
          flex: 1,
        }}
      >
        {title}
      </Typography>
    </Stack>
    <IconButton
      sx={{
        zIndex: '66',
      }}
      onClick={onClose}
      variant="base"
    >
      <CloseIcon />
    </IconButton>
  </Stack>
);

export default DesktopHeader;
