import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'toolbar',
  initialState: { isOpen: true },
  reducers: {
    open(state) {
      state.isOpen = true;
    },
    close(state) {
      state.isOpen = false;
    },
  },
});

export { actions as toolbarActions };
export { reducer as toolbarReducer };
