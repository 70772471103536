import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';

import { useDispatch } from 'react-redux';
import { ReactComponent as Alert } from '../resources/images/new_icons/alert.svg';
import { ReactComponent as Reports } from '../resources/images/new_icons/common-file-text 1.svg';
import { ReactComponent as Settings } from '../resources/images/new_icons/config.svg';
import { ReactComponent as Map } from '../resources/images/new_icons/pin-map 1.svg';
import { eventsSideBarActions, sidebarActions } from '../store';
import SidebarItem from './SidebarItem';

const Sidebar = ({ setKeyword }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState('map');

  const handleSelection = (event, value) => {
    switch (value) {
      case 'map':
        navigate('/');
        setSelected('map');
        dispatch(sidebarActions.close());
        dispatch(eventsSideBarActions.close());
        break;
      case 'events':
        navigate('/');
        setSelected('events');
        dispatch(sidebarActions.close());
        dispatch(eventsSideBarActions.open('sideBar'));
        break;
      case 'reports':
        navigate('/reports/route');
        setSelected('reports');
        break;
      case 'settings':
        navigate('/settings/preferences');
        setSelected('settings');
        break;
      default:
        break;
    }
  };

  return (
    <Stack justifyContent="center" spacing="40px" width="100%">
      <SidebarItem
        selected={selected}
        handleSelection={handleSelection}
        name="map"
      >
        <Map />
      </SidebarItem>

      <SidebarItem
        selected={selected}
        handleSelection={handleSelection}
        name="events"
      >
        <Alert />
      </SidebarItem>

      <SidebarItem
        selected={selected}
        handleSelection={handleSelection}
        name="reports"
        setKeyword={setKeyword}
      >
        <Reports />
      </SidebarItem>

      <SidebarItem
        selected={selected}
        handleSelection={handleSelection}
        name="settings"
      >
        <Settings />
      </SidebarItem>
    </Stack>
  );
};

export default Sidebar;
