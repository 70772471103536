import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Box, Divider, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import DialogPageContainer from '../DialogPageContainer';
import DesktopHeader from '../DesktopHeader';
import MobileHeader from '../MobileHeader';
import { IconFilter } from '../icons';
import SeverityFilter from './components/SeverityFilter';
import StateFilter from './components/StateFilter';
import GroupFilter from './components/GroupFilter';
import { filterSideBarActions } from '../../../store';

const FiltersTab = ({ filter, setFilter }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <DialogPageContainer>
      <Box position="relative" width="100%" padding="28px">
        {desktop && (
        <DesktopHeader
          altIcon={<IconFilter />}
          title="Filtrar Busca"
          onClose={() => dispatch(filterSideBarActions.close())}
        />
        )}
        {!desktop && <MobileHeader onClose={() => dispatch(filterSideBarActions.close())} title="Filtrar Busca" />}
      </Box>
      <Box width="100%">
        <Divider orientation="horizontal" />
      </Box>
      <Stack gap="16px" width="100%" padding="32px 0px 32px 32px">
        <Stack gap="4px">
          <Typography color="#333" variant="tooltip">Gravidade dos eventos</Typography>
          <Typography variant="tooltip-subtext">Selecione os níveis de gravidade dos eventos</Typography>
        </Stack>

        <SeverityFilter filter={filter} setFilter={setFilter} />

      </Stack>

      <Box padding="0 32px" width="100%">
        <Divider orientation="horizontal" />
      </Box>
      <Stack gap="16px" width="100%" padding="32px 0px 32px 32px">
        <Stack gap="4px">
          <Typography color="#333" variant="tooltip">Grupos</Typography>
          <Typography variant="tooltip-subtext">Detalhes sobre a função dos grupos</Typography>
        </Stack>

        <GroupFilter filter={filter} setFilter={setFilter} />

      </Stack>

      <Box padding="0 32px" width="100%">
        <Divider orientation="horizontal" />
      </Box>

      <Stack gap="16px" width="100%" padding="32px 0px 32px 32px">
        <Stack gap="4px">
          <Typography color="#333" variant="tooltip">Selecione um estado</Typography>
        </Stack>

        <StateFilter filter={filter} setFilter={setFilter} />

      </Stack>

    </DialogPageContainer>
  );
};

export default FiltersTab;
