import { styled, Stack } from '@mui/material';

const Container = styled(Stack)`
    background-color: #fff;
    position: absolute;
    bottom: 12px; 
    left: 12px;
    border-radius: 60px;
    box-shadow: 1px 1px 20px grey;

`;

export default Container;
