import React, { useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Divider, Grid, Stack, Typography, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { eventsSideBarActions, sidebarActions } from '../../../../store';
import api from '../../../../Http';
import EventCount from '../../EventCount';
import MobileEventBar from './MobileEventBar';
import EventButtonContainer from './EventButtonContainer';
import CardInfos from './CardInfos';
import { IconKey } from '../../icons';
import placeHolder from '../../../../placeholder.png';
import ActionsDialog from './ActionDialog';

const CarTab = ({ positionInfo, carInfo }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const [modalInfo, setModalInfo] = useState(null);
  const [sucess, setSucess] = useState(false);
  const carId = useSelector((state) => state.devices.selectedId);

  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  const handleShowEvents = (mobile) => {
    if (mobile) {
      dispatch(sidebarActions.close());
    }
    dispatch(eventsSideBarActions.open('carCard'));
  };

  async function sendCommand(type) {
    if (!carId) return;

    await api.post('/api/commands/send', {
      deviceId: carId,
      attributes: {},
      type,
    });
    setSucess(true);
  }

  function convertToKM(valueInMetters) {
    return (valueInMetters / 1000).toFixed(0);
  }

  return (
    <>
      <Box width="100%" padding="0 28px 28px 28px">

        <Stack direction="row" justifyContent="space-between" maxHeight="112px" marginTop={desktop ? '40px' : '24px'}>
          <img width={desktop ? '210px' : '156px'} height="100%" alt="carro" src={placeHolder} />

          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box
              sx={{
                borderRadius: '12px',
                border: '1px solid #E0E0E0',
                padding: desktop ? '12px 37px' : '16px 24px',
                minWidth: desktop ? '180px' : '134px',
              }}
            >
              <Typography
                sx={{
                  color: '#121212',
                  fontFamily: 'Montserrat',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '36px',
                  textAlign: 'center',
                }}
              >
                {carInfo?.licensePlate ?? 'AB123CD'}
              </Typography>
            </Box>
            <Box gap="12px" display="flex" justifyContent="center">
              <Typography variant="caption-gray">
                {carInfo?.model ?? 'Modelo'}
              </Typography>
              <Typography variant="caption-gray">
                {carInfo?.attributes?.Cor ?? ''}
              </Typography>
            </Box>
          </Box>
        </Stack>

        {desktop && (
          <Stack
            direction="row"
            display="flex"
            marginTop="40px"
            alignItems="center"
            spacing="22px"
          >
            <Typography
              fontWeight={700}
              fontSize="16px"
              lineHeight="24px"
              variant="base"
            >
              Eventos
            </Typography>
            <Stack direction="row" gap="12px">
              <Button onClick={() => handleShowEvents()} variant="base">
                <EventCount severity="low">
                  <Typography variant="event-count">
                    {carInfo?.eventCount?.Baixo}
                  </Typography>
                </EventCount>
                <Typography
                  fontWeight={600}
                  fontSize="12px"
                  lineHeight="18px"
                  variant="base"
                  textTransform="none"
                >
                  Baixo Risco
                </Typography>
              </Button>

              <Button onClick={() => handleShowEvents()} variant="base">
                <EventCount severity="medium">
                  <Typography variant="event-count">
                    {carInfo?.eventCount?.Medio}
                  </Typography>
                </EventCount>
                <Typography
                  fontWeight={600}
                  fontSize="12px"
                  lineHeight="18px"
                  variant="base"
                  textTransform="none"
                >
                  Médio Risco
                </Typography>
              </Button>
              <Button onClick={() => handleShowEvents()} variant="base">
                <EventCount severity="critical">
                  <Typography variant="event-count">
                    {carInfo?.eventCount?.Critico}
                  </Typography>
                </EventCount>
                <Typography
                  fontWeight={600}
                  fontSize="12px"
                  lineHeight="18px"
                  variant="base"
                  textTransform="none"
                >
                  Crítico
                </Typography>
              </Button>
            </Stack>
          </Stack>
        )}

        {!desktop && (
          <MobileEventBar onClick={() => handleShowEvents()} eventCount={carInfo?.eventCount ?? { Baixo: 0, Médio: 0, Crítico: 0 }} />)}

      </Box>

      <Box width="100%">
        <Divider orientation="horizontal" />
      </Box>

      <Box width="100%" alignItems="center">
        <Box
          padding="32px 16px"
          width="100%"
          display="flex"
          alignItems="center"
          gap={desktop ? '12px' : '8px'}
          overflow={desktop ? 'hidden' : 'auto'}
        >
          <EventButtonContainer
            callback={() => {
              setModalInfo({ text: 'Imobilizar', icon: 'IconKey', callback: () => sendCommand('engineStop') });
            }}
            text="Imobilizar"
            icon={<IconKey />}
          />

          <EventButtonContainer
            callback={() => {
              setModalInfo({ text: 'Liberar', icon: 'IconKey', callback: () => sendCommand('engineResume') });
            }}
            text="Liberar"
            icon={<IconKey />}
          />

        </Box>
      </Box>
      <ActionsDialog sucess={sucess} setSucess={setSucess} modalInfo={modalInfo} setModalInfo={setModalInfo} />

      <Box width="100%">
        <Divider orientation="horizontal" />
      </Box>

      <Box width="100%">
        {!desktop && (
          <Stack direction="row" spacing="16px" overflow="auto" padding="32px 16px 64px">
            {positionInfo?.attributes?.batteryLevel > 0 && (
              <CardInfos name="Nivel de bateria" value={positionInfo?.attributes?.batteryLevel} icon="Power" type="percent" />
            )}
            <CardInfos name="Nivel Combustível" value={positionInfo?.attributes?.fuel} icon="GasLevel" type="percent" />
            <CardInfos name="Odometro" value={convertToKM(positionInfo?.attributes?.totalDistance)} icon="TripDistance" type="km" />
            <CardInfos name="Status da Conexão" value={carInfo?.status ?? 'Desconhecido'} icon="WifiSignal" type="online" />
          </Stack>
        )}

        {desktop && (
          <Grid rowSpacing="24px" spacing="24px" container padding="32px">
            <Grid item xs={6}>
              <CardInfos name="Status da Conexão" value={carInfo?.status === 'online' ? 'online' : (carInfo ? dayjs(carInfo?.lastUpdate).fromNow() : 'offline')} type="online" icon="WifiSignal" />
            </Grid>
            {positionInfo?.attributes?.batteryLevel > 0 && (
              <Grid item xs={6}>
                <CardInfos name="Nivel de bateria" value={Math.trunc(positionInfo?.attributes?.batteryLevel)} type="percent" icon="Power" />
              </Grid>
            )}
            <Grid item xs={6}>
              <CardInfos name="Nivel Combustível" value={positionInfo?.attributes?.fuel} type="percent" icon="GasLevel" />
            </Grid>
            <Grid item xs={6}>
              <CardInfos name="Odometro" value={convertToKM(positionInfo?.attributes?.totalDistance)} type="km" icon="TripDistance" />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default CarTab;
