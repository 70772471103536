import { IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { Car, IconBackArrow } from './icons';

const MobileHeader = ({ title, onClose, goBack }) => (
  goBack ? (
    <Stack
      direction="row"
      width="100%"
      spacing="12px"
    >
      <IconButton
        onClick={onClose}
        variant="base"
      >
        <IconBackArrow />
      </IconButton>
      <Stack gap="14px" width="100%" direction="row" alignItems="center">
        <div
          style={{
            width: '48px',
            height: '48px',
            display: 'flex',
            borderRadius: '100%',
            background: '#F7EEFF',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Car />
        </div>
        <Typography
          sx={{
            color: '#121212',
            fontSize: '16px',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '24px',
            flex: 1,
          }}
        >
          {title}
        </Typography>
      </Stack>

    </Stack>
  ) : (
    <Stack
      direction="row"
      sx={{
        width: '100%',
      }}
    >
      <Stack gap="14px" width="100%" direction="row" alignItems="center">
        <div
          style={{
            width: '48px',
            background: '#F7EEFF',
            height: '48px',
            borderRadius: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Car />
        </div>
        <Typography
          sx={{
            color: '#121212',
            fontSize: '16px',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '24px',
            flex: 1,
          }}
        >
          {title}
        </Typography>
      </Stack>
      <IconButton
        sx={{
          zIndex: '66',
        }}
        onClick={onClose}
        variant="base"
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  )
);

export default MobileHeader;
