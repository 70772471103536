import styled from '@emotion/styled';

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  position: absolute;
  z-index: 55;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default LoaderContainer;
