import { styled, Box } from '@mui/material';

const EventCount = styled(Box)`
            display: flex;
            width: 20px;
            min-width: 20px;
            height: 20px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 40px;
            background: ${({ severity }) => (severity === 'low' ? '#F2C94C' : severity === 'medium' ? '#F2994A' : '#EB5757')};
`;

export default EventCount;
