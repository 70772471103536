import {
  Box, CircularProgress, Divider, Stack,
} from '@mui/material';
import { React, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { driversActions, eventDriverInfoActions } from '../../../store';

import LoaderContainer from '../LoaderContainer';
import DialogPageContainer from '../DialogPageContainer';
import DesktopHeader from '../DesktopHeader';
import DriverInfoField from './Components/DriverInfoField';

const DriverInfoCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const currentDriver = useSelector((state) => state.drivers.selectedDriver);

  const handleCloseDriverInfo = () => {
    dispatch(eventDriverInfoActions.close());
    dispatch(driversActions.clearDriver());
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <DialogPageContainer zIndex={8}>
      {isLoading && (
        <LoaderContainer>
          <CircularProgress color="inherit" />
        </LoaderContainer>
      )}

      <Box position="relative" width="100%" padding="24px 32px">
        <DesktopHeader
          title="Dados do condutor"
          onClose={handleCloseDriverInfo}
        />
      </Box>

      <Box width="100%">
        <Divider orientation="horizontal" />
      </Box>

      <Stack width="100%" padding="32px" spacing="24px">
        <DriverInfoField label="Nome Completo" value={currentDriver.name} />
        <DriverInfoField label="E-mail" value={currentDriver.email} />
        <DriverInfoField label="Celular" value={currentDriver.cellphone} />
        <DriverInfoField
          label="Contato de emergência"
          value={currentDriver.emergencyContact}
        />
        <DriverInfoField label="CPF" value={currentDriver.cpf} />
        <DriverInfoField label="Endereço" value={currentDriver.address} />
      </Stack>
    </DialogPageContainer>
  );
};

export default DriverInfoCard;
