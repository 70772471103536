import {
  Box, CircularProgress, Stack, Typography,
} from '@mui/material';
import React from 'react';
import { IconGasLevel, IconTripDistance, IconWifiSignal } from '../../icons';

const RenderValueType = ({ type, value }) => {
  let component;

  switch (type) {
    case 'percent':
      component = (
        <Typography
          sx={{
            color: '#8000FF',
            fontFamily: 'Montserrat',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
          }}
          variant="base"
        >
          {value ?? 0}
          %
        </Typography>
      );
      break;
    case 'km':
      component = (
        <Stack direction="row">
          <Typography
            sx={{
              color: '#121212',
              fontFamily: 'Montserrat',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: 'normal',
            }}
          >
            {value || '-'}
            {' '}
            Km
          </Typography>
          {/* <Typography
            sx={{
              color: '#BDBDBD',
              fontFamily: 'Montserrat',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: 'normal',
            }}
          >
            /3000
          </Typography> */}
        </Stack>
      );
      break;

    case 'online':
      component = (
        <Typography
          sx={{
            color: '#8000FF',
            fontFamily: 'Montserrat',
            fontSize: 18,
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
          }}
          variant="base"
        >
          {value}
        </Typography>
      );
      break;
    default: component = '';
      break;
  }
  return component;
};

const RenderIcon = ({ type }) => {
  let component;

  switch (type) {
    case 'GasLevel':
      component = (
        <IconGasLevel style={{
          position: 'absolute',
          top: '8px',
          left: '8px',
        }}
        />
      );
      break;
    case 'TripDistance':
      component = (
        <IconTripDistance style={{
          position: 'absolute',
          top: '8px',
          left: '8px',
        }}
        />
      );
      break;

    case 'WifiSignal':
      component = (
        <IconWifiSignal style={{
          position: 'absolute',
          top: '8px',
          left: '8px',
        }}
        />
      );
      break;
    default: component = '';
      break;
  }
  return component;
};

const CardInfos = ({ name, value, type, icon }) => (
  <Stack
    gap="24px"
    sx={{
      width: '229px',
      minWidth: '200px',
      height: '152px',
      background: '#F2F2F2',
      padding: '16px',
      borderRadius: '16px',
    }}
  >
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        value={typeof value === 'number' ? value : 0}
      />
      <RenderIcon type={icon} />
    </Box>

    <Stack gap="8px">
      {' '}
      <Typography
        color="black"
        fontWeight={500}
        fontSize="12px"
        lineHeight="18px"
        variant="base"
      >
        {name}
      </Typography>
      <RenderValueType type={type} value={value} />
    </Stack>
  </Stack>
);

export default CardInfos;
