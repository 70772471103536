import {
  Box, Divider, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import placeHolder from '../../../../assets/placeholder_2.png';
import EventCount from '../../EventCount';
import { devicesActions, eventsSideBarActions, sidebarActions } from '../../../../store';

const CarItem = ({ device, setKeyword }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  const onMarkerClick = useCallback((deviceId) => {
    dispatch(devicesActions.selectId(deviceId));

    if (typeof deviceId === 'number') {
      setKeyword('');
      dispatch(sidebarActions.open());
      dispatch(eventsSideBarActions.close());
    }
  }, [dispatch]);
  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        onMarkerClick(device?.id);
      }}
      width={desktop ? '482px' : '100%'}
      height={desktop ? '160px' : '190px'}
      sx={{
        cursor: 'pointer',
        borderRadius: '12px',
        border: '1px solid #E0E0E0',
        background: '#FFF',
        '&:hover': {
          background: '#F2F2F2',
        },
      }}
    >

      <Stack direction="column">
        <Stack padding={desktop ? '24px' : '16px'} paddingBottom="19px" gap={desktop ? '58px' : '11px'} direction={desktop ? 'row' : 'column'}>
          <Stack justifyContent={desktop ? 'auto' : 'space-between'} direction="row" gap={desktop ? '8px' : '0px'}>
            <img
              width="78px"
              height="50px"
              src={placeHolder}
              alt="imagem veiculo"
            />

            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box
                sx={{
                  borderRadius: '12px',
                  border: '1px solid #E0E0E0',
                  padding: '12px 16px',
                  background: '#FFF',
                }}
              >
                <Typography
                  sx={{
                    color: '#121212',
                    fontFamily: 'Montserrat',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: 'normal',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {device?.licensePlate ?? 'AB123CD'}
                </Typography>
              </Box>

            </Box>
          </Stack>
          <Stack
            sx={desktop ? {
              width: '100%',
              alignItems: 'end',
            } : {

            }}
            gap="4px"
          >
            <Stack direction={desktop ? 'column' : 'row'} spacing={desktop ? 0 : 2}>
              <Stack>
                <Stack gap={desktop ? '12px' : '7px'} justifyContent={desktop ? 'auto' : 'auto'} direction="row">
                  <Typography variant="car-info-searchbar">
                    {device?.model ?? 'Modelo'}
                  </Typography>
                  <Typography variant="car-info-searchbar" fontWeight={500}>
                    {device?.attributes?.Cor ?? 'Cor'}
                  </Typography>
                </Stack>
                <Stack gap={desktop ? '12px' : '13px'} justifyContent={desktop ? 'auto' : 'auto'} direction="row">
                  <Typography variant="car-info-searchbar">
                    Chassi
                  </Typography>
                  <Typography variant="car-info-searchbar" fontWeight={500}>
                    {device?.uniqueId}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Stack gap={desktop ? '12px' : '7px'} justifyContent={desktop ? 'auto' : 'auto'} direction="row">
                  <Typography variant="car-info-searchbar">
                    Conexão:
                  </Typography>
                  <Typography variant="car-info-searchbar" fontWeight={500}>
                    {device?.status === 'online' ? 'online' : (device ? dayjs(device?.lastUpdate).fromNow() : 'offline')}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {/* acc */}

        <Box width="100%">
          <Divider orientation="horizontal" />
        </Box>
        <Stack padding="16px 16px" gap={desktop ? '67px' : '55px'} direction="row">
          <Typography sx={{
            color: '#333',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '18px', /* 128.571% */
          }}
          >
            Eventos

          </Typography>
          <Stack gap="24px" direction="row">
            <Stack direction="row" gap="8px">
              <EventCount severity="low">
                <Typography variant="event-count">
                  {device?.eventCount?.Baixo}
                </Typography>
              </EventCount>
              <Typography
                fontWeight={500}
                whiteSpace="nowrap"
                fontSize="12px"
                lineHeight="18px"
                variant="base"
                textTransform="none"
              >
                Baixo Risco
              </Typography>
            </Stack>
            {
              desktop && (
              <Stack direction="row" gap="8px">
                <EventCount severity="medium">
                  <Typography variant="event-count">
                    {device?.eventCount?.Medio}
                  </Typography>
                </EventCount>
                <Typography
                  fontWeight={500}
                  whiteSpace="nowrap"
                  fontSize="12px"
                  lineHeight="18px"
                  variant="base"
                  textTransform="none"
                >
                  Médio Risco
                </Typography>
              </Stack>
              )
            }
            <Stack direction="row" gap="8px">
              <EventCount severity="critical">
                <Typography variant="event-count">
                  {device?.eventCount?.Critico ?? 0}
                </Typography>
              </EventCount>
              <Typography
                fontWeight={500}
                whiteSpace="nowrap"
                fontSize="12px"
                lineHeight="18px"
                variant="base"
                textTransform="none"
              >
                Crítico
              </Typography>
            </Stack>
          </Stack>
        </Stack>

      </Stack>

    </Box>
  );
};

export default CarItem;
