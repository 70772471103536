import * as React from 'react';
import {
  Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import StyledToggleButtonGroup from './CustomButtonGroup';
import CustomButton from './CustomButton';
import { IconSelected, IconUnselected } from '../../icons';

const StateFilter = ({ filter, setFilter }) => {
  const handleFormat = (event, newFormats) => {
    setFilter((prev) => {
      const newValue = { ...prev };
      newValue.statuses = newFormats;
      return newValue;
    });
  };
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <StyledToggleButtonGroup
      value={filter.statuses}
      onChange={handleFormat}
      aria-label="text formatting"
      flexDirection="column"
    >
      <CustomButton showBorder={desktop} value="online" aria-label="bold">
        <Stack gap="16px" direction="row">
          {!desktop && (
          <>
            {' '}
            {filter.statuses.includes('online') ? <IconSelected /> : <IconUnselected />}
          </>
          )}
          <Typography variant="button-text">Conectado</Typography>
          {!desktop && <div />}
        </Stack>

      </CustomButton>
      <CustomButton showBorder={desktop} value="offline" aria-label="italic">
        <Stack gap="16px" direction="row">
          {!desktop && (
          <>
            {' '}
            {filter.statuses.includes('offline') ? <IconSelected /> : <IconUnselected />}
          </>
          )}
          <Typography variant="button-text">Desconectado</Typography>
        </Stack>

      </CustomButton>
      <CustomButton showBorder={desktop} value="unknown" aria-label="underlined">
        <Stack gap="16px" direction="row">

          {!desktop && (
          <>
            {' '}
            {filter.statuses.includes('unknown') ? <IconSelected /> : <IconUnselected />}
          </>
          )}
          <Typography variant="button-text">Desconhecido</Typography>

        </Stack>
      </CustomButton>
    </StyledToggleButtonGroup>
  );
};

export default StateFilter;
