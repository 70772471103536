import {
  Button, Divider, Paper, Stack, Typography, useMediaQuery,
} from '@mui/material';
import React from 'react';
import { ChevronRight } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useTheme } from '@emotion/react';
import { IconWhiteCheck } from '../../icons';
import { driversActions, eventDriverInfoActions } from '../../../../store';

const DriverItem = ({ currentDriver }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const userPhoto = currentDriver?.attributes?.photo;

  const handleOpenCarDriverInfo = () => {
    dispatch(eventDriverInfoActions.open());
    dispatch(driversActions.selectDriver(currentDriver));
  };

  //   currentDriver {
  //     "id": 2,
  //     "attributes": {},
  //     "name": "Murilo",
  //     "uniqueId": "1234567890",
  //     "email": null,
  //     "cellphone": null,
  //     "emergencyContact": null,
  //     "cpf": null,
  //     "rg": "1999999999",
  //     "address": null,
  //     "disabled": false,
  //     "creationDate": "2023-12-18T18:44:37.427+00:00",
  //     "expirationTime": null,
  //     "lastUpdate": "2023-12-18T18:45:24.552+00:00"
  // }

  return (

    <Paper sx={{ borderRadius: '16px', '& > .MuiStack-root:not(.skip)': { padding: desktop ? '24px' : '16px' } }} elevation={0} variant="outlined">
      <Stack direction="row" alignItems="center" justifyContent="space-between">

        <Stack direction="row" alignItems="center" spacing={desktop ? '16px' : '12px'}>
          <img height={desktop ? '64px' : '40px'} width={desktop ? '64px' : '40px'} src={userPhoto || `https://via.placeholder.com/${desktop ? '64' : '40'}`} alt="car" style={{ borderRadius: '12px' }} />

          <Stack>
            <Typography variant="nav-legend" color="#8000FF">
              Condutor Ativo
            </Typography>
            <Typography variant="base" fontWeight="700">
              {currentDriver.name}
            </Typography>
          </Stack>
        </Stack>

        <Button variant="base" onClick={handleOpenCarDriverInfo}>
          <Typography variant="nav-legend" textTransform="none">{desktop ? 'Dados do condutor' : 'Dados'}</Typography>
          <ChevronRight />
        </Button>

      </Stack>

      <Stack className="skip" direction="row" alignItems="center" spacing="8px" p="8px" mx={desktop ? '24px' : '16px'} borderRadius="42px" sx={{ background: '#F7EEFF' }}>
        <IconWhiteCheck />
        <Typography variant="caption-gray" fontWeight={400} textAlign="left" whiteSpace="nowrap">
          Condutor Ativo desde
          <strong style={{ display: 'inline' }}>{moment(currentDriver.creationDate).utc().format(desktop ? ' DD [de] MMMM [de] YYYY' : ' DD/MM/YYYY')}</strong>
        </Typography>
      </Stack>

      <Divider sx={{ mt: '24px' }} />

      <Stack direction="row" spacing="16px" sx={{ '& .MuiPaper-root': { paddingY: '12px', flex: '1', textAlign: 'center', borderRadius: '12px' } }}>

        <Paper elevation={0} variant="outlined">
          <Typography variant="tooltip" color="#333" width="100%" display="block">3</Typography>
          <Typography variant="event-header" fontSize="12px" fontWeight="500">Locações</Typography>
        </Paper>
        <Paper elevation={0} variant="outlined">
          <Typography variant="tooltip" color="#333" width="100%" display="block" textAlign="center">3</Typography>
          <Typography variant="event-header" fontSize="12px" fontWeight="500">KM&apos;s rodados</Typography>
        </Paper>
      </Stack>

    </Paper>

  );
};

export default DriverItem;
