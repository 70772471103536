/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment-timezone';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import usePersistedState from '../common/util/usePersistedState';
import useFilter from './useFilter';
import MainToolbar from './MainToolbar';
import MainMap from './MainMap';
import Sidebar from './Sidebar';
import { useAttributePreference } from '../common/util/preferences';
import DeviceInfoPanel from './DeviceInfoPanel';
import EventsCard from '../common/components/EventsCard/EventsCard';
import api from '../Http';
import CarCard from '../common/components/CarCard/CarCard';
import DriverInfoCard from '../common/components/DriverInfoCard/DriverInfoCard';
import { devicesActions, eventModalActions, toolbarActions } from '../store';
import {
  IconCheck,
  IconEventNotification,
  IconEventNotificationRead,
} from '../common/components/icons';
import SearchTab from '../common/components/SearchTab/SearchTab';
import FiltersTab from '../common/components/FiltersTab/FiltersTab';
import eventNames from '../resources/l10n/pt_BR.json';
import ReportComponent from './ReportContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  sidebar: {
    width: '80px',
    height: 'calc(100vh - 80px)',
    display: 'flex',
    alignItems: 'center',
  },
  floatingInfo: {
    position: 'absolute',
    bottom: '48px',
    right: '48px',
    padding: '16px',
    zIndex: 4,
    minWidth: '800px',
    height: '115px',
    borderRadius: '16px',

    '& div > .MuiDivider-root': {
      margin: '0 24px',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  footer: {
    pointerEvents: 'auto',
    zIndex: 5,
  },
  middle: {
    flex: 1,
    display: 'grid',
  },
  contentMap: {
    pointerEvents: 'auto',
    gridArea: '1 / 1',
  },
  contentList: {
    pointerEvents: 'auto',
    gridArea: '1 / 1',
    zIndex: 4,
  },
  desktopMap: {
    height: 'calc(100vh - 80px)',
    width: '100%',
    '& .maplibregl-ctrl.maplibregl-ctrl-group': {
      opacity: 0,
      pointerEvents: 'none',
    },
  },
}));

const MainPage = () => {
  const teste = true;
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const location = useLocation();

  const shouldHideMap = location.pathname !== '/';

  const showCarCard = useSelector((state) => state.sidebar.isOpen);
  const showEventsCard = useSelector((state) => state.eventsSideBar.isOpen);
  const showEventModal = useSelector((state) => state.eventModal.isOpen);
  const showDriverInfo = useSelector((state) => state.eventDriverInfo.isOpen);
  const eventInfo = useSelector((state) => state.eventModal.event);
  const showFiltersCard = useSelector((state) => state.filterSideBar.isOpen);

  const mapOnSelect = useAttributePreference('mapOnSelect', true);

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const devices = useSelector((state) => state.devices.items);
  const positions = useSelector((state) => state.session.positions);
  const [filteredPositions, setFilteredPositions] = useState([]);
  const selectedPosition = filteredPositions.find(
    (position) => selectedDeviceId && position.deviceId === selectedDeviceId,
  );

  function capitalizeFirstLetter(str) {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const [filteredDevices, setFilteredDevices] = useState([]);

  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    if (!keyword) {
      dispatch(toolbarActions.open());
      return;
    }
    dispatch(toolbarActions.close());
  }, [keyword]);

  useEffect(() => {
    if (!showFiltersCard) {
      dispatch(toolbarActions.open());
      return;
    }
    dispatch(toolbarActions.close());
  }, [showFiltersCard]);
  const [filter, setFilter] = usePersistedState('filter', {
    statuses: [],
    groups: [],
    severity: [],
  });
  const [filterSort, setFilterSort] = usePersistedState('filterSort', '');
  const [filterMap, setFilterMap] = usePersistedState('filterMap', false);

  const [isLoading, setIsLoading] = useState(true);
  const [carInfo, setCarInfo] = useState(undefined);
  const [positionInfo, setPositionInfo] = useState(undefined);
  const carId = useSelector((state) => state.devices.selectedId);

  async function getPosition(positionId) {
    try {
      if (!positionId && positionId !== 0) return;
      const response = await api.get(`/api/positions?id=${positionId}`);
      setPositionInfo(response.data[0]);
    } finally {
      setIsLoading(false);
    }
  }

  const getCarInfo = async () => {
    if (!carId) return;
    try {
      setIsLoading(true);
      const response = await api.get(`/api/devices?id=${carId}&events=true&drivers=true`);
      const { positionId } = response.data[0];
      setIsLoading(false);
      getPosition(positionId);
      setCarInfo(response.data[0]);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCarInfo(carId);
  }, [carId]);

  const refreshDevice = async (deviceId) => {
    const response = await api.get(`/api/devices?id=${deviceId}&events=true`);
    if (response.status === 200) {
      dispatch(devicesActions.singleRefresh(response?.data[0]));
    }
  };

  const resolve = async (event) => {
    const response = await api.put(`api/events/resolve?id=${event.eventId}`);
    if (response.status === 200) {
      if (event?.marker) {
        event.marker.remove();
      }
      dispatch(eventModalActions.close());
      getCarInfo(carId);
      refreshDevice(event.deviceId);
    }
  };

  const [devicesOpen, setDevicesOpen] = useState(desktop);

  useEffect(() => {
    if (!desktop && mapOnSelect && selectedDeviceId) {
      setDevicesOpen(false);
    }
  }, [desktop, mapOnSelect, selectedDeviceId]);

  useFilter(
    keyword,
    filter,
    filterSort,
    filterMap,
    positions,
    setFilteredDevices,
    setFilteredPositions,
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <MainToolbar
          filteredDevices={filteredDevices}
          devicesOpen={devicesOpen}
          setDevicesOpen={setDevicesOpen}
          keyword={keyword}
          setKeyword={setKeyword}
          filter={filter}
          setFilter={setFilter}
          filterSort={filterSort}
          setFilterSort={setFilterSort}
          filterMap={filterMap}
          setFilterMap={setFilterMap}
        />
      </div>

      <Stack
        position="relative"
        direction="row"
        sx={desktop ? {} : { height: 'calc(100vh - 151px)' }}
      >
        {desktop && (
          <Paper className={classes.sidebar}>
            <Sidebar setKeyword={setKeyword} />
          </Paper>
        )}
        {(showCarCard || showEventsCard || keyword || showFiltersCard) && (
          <Stack
            id="modal-container"
            sx={{
              position: 'absolute',
              top: desktop ? '0px' : '-150px',
              left: desktop ? '74px' : '0px',
              height: desktop ? 'calc(100vh - 80px)' : '100%',
            }}
            direction="row"
          >
            {keyword && (
              <SearchTab
                setKeyword={setKeyword}
                filteredDevices={filteredDevices}
              />
            )}

            {showFiltersCard && (
              <FiltersTab filter={filter} setFilter={setFilter} />
            )}
            {showCarCard && (
              <CarCard
                isLoading={isLoading}
                carInfo={carInfo}
                positionInfo={positionInfo}
              />
            )}
            {showEventsCard && (
              <EventsCard
                carId={carId}
                getCarInfo={getCarInfo}
                carInfo={carInfo}
                showCarCard={showCarCard}
              />
            )}
            {showDriverInfo && <DriverInfoCard />}
          </Stack>
        )}

        {shouldHideMap && (
        <div style={desktop ? { width: '100%' } : { height: '100%' }}>
          <Outlet />
        </div>
        )}

        {!shouldHideMap && (
          <div
            className={classes.desktopMap}
            style={desktop ? {} : { height: '100%' }}
          >
            <MainMap
              filteredPositions={filteredPositions}
              selectedPosition={selectedPosition}
            />
          </div>
        )}
      </Stack>

      {desktop && !showCarCard && !showEventsCard && !showDriverInfo && !shouldHideMap && (
        <Paper className={classes.floatingInfo}>
          <DeviceInfoPanel />
        </Paper>
      )}

      <Dialog
        sx={
          desktop
            ? { left: '70px', bottom: '160px' }
            : { left: '0', bottom: '160px' }
        }
        hideBackdrop
        open={showEventModal}
        onClose={() => dispatch(eventModalActions.close())}
      >
        <Paper
          sx={{
            width: desktop ? '397px' : 'auto',
            height: '212px',
            borderRadius: '12px',
            boxShadow: 'none',
          }}
        >
          <Stack
            padding={desktop ? 'none' : '0 24px'}
            height="100%"
            width="100%"
            alignItems="center"
          >
            <Typography
              marginTop="23px"
              marginBottom="16px"
              variant="event-modal"
            >
              <div>
                {`Veículo: ${devices[eventInfo?.deviceId]?.licensePlate}`}
                <br />
                {`Data e Hora: ${moment.tz(eventInfo?.eventTime, 'America/Sao_Paulo').format('D [de] MMMM [de] YYYY - HH:mm')}`}
              </div>
            </Typography>

            <Stack
              marginBottom="24px"
              sx={{
                minHeight: '18px',
                display: 'flex',
                width: desktop ? '369px' : '100%',
                alignItems: 'center',
                gap: '10px',
                borderRadius: '52px',
                border: '1px solid #E0E0E0',
                position: 'relative',
                justifyContent: 'center',
                padding: '12px 44px',
              }}
              direction="row"
              gap="36.5px"
            >
              <Box
                sx={{
                  display: 'flex',
                  padding: '8px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                  borderRadius: '40px',
                  background: 'rgba(242, 153, 74, 0.10)',
                  width: 'max-content',
                  height: 'max-content',
                  position: 'absolute',
                  left: '4px',
                }}
              >
                <IconEventNotification
                  style={{ color: '#F2994A', width: '16px', height: '16px' }}
                />
              </Box>
              <Typography variant="event-header">
                {eventInfo?.type === 'alarm' ? eventNames[`alarm${capitalizeFirstLetter(eventInfo?.attributes?.alarm)}`] : eventNames[`event${capitalizeFirstLetter(eventInfo?.type)}`]}
              </Typography>
            </Stack>

            <Box marginBottom="17px" width="100%">
              <Divider orientation="horizontal" />
            </Box>

            <IconButton
              onClick={() => resolve({ eventId: eventInfo.id, deviceId: eventInfo.deviceId, marker: eventInfo.marker })}
              sx={{
                display: 'flex',
                width: desktop ? '349px' : '100%',

                justifyContent: 'center',
                alignItems: 'center',
                gap: '12px',
                borderRadius: '50px',
                background: '#27AE60',
                height: '32px',
                '&:hover': {
                  background: '#27AE60',
                },
              }}
            >
              <IconCheck />
            </IconButton>
          </Stack>
        </Paper>
      </Dialog>
    </div>
  );
};

export default MainPage;
