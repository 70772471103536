import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'sidebar',
  initialState: {
    isOpen: false,
  },
  reducers: {
    open(state) {
      state.isOpen = true;
    },
    close(state) {
      state.isOpen = false;
    },
  },
});

export { actions as sidebarActions };
export { reducer as sidebarReducer };
