import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Box, Typography, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import UpdateList from './UpdatesList';
import api from '../../../../Http';

const UpdatesTab = ({ carInfo }) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [eventList, setEventList] = useState([]);

  const limit = 25;

  const getCarEvents = useCallback(async () => {
    try {
      if (eventList.length >= count && count !== 0) return;
      setIsLoading(true);
      const response = await api.get('api/events/search/telemetry', {
        params: { deviceId: carInfo.id, count: true, telemetry: true, limit, offset: eventList.length },
      });
      const info = response?.data?.Atualizacao ?? [];
      setEventList((prev) => [...prev, ...info]);
      setCount(response.data.count);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [eventList, carInfo?.id]);

  useEffect(() => {
    setEventList([]);
    getCarEvents();
  }, [carInfo?.id]);

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          getCarEvents();
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, eventList, carInfo?.id]);

  return (
    <Box width="100%">

      <Box padding={`0 ${!desktop ? '16px' : '32px'}`}>
        <Typography
          sx={{
            color: '#121212',
            fontFamily: 'Montserrat',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '27px',
          }}
        >
          Atualizações do veículo
        </Typography>

      </Box>
      <UpdateList isLoading={isLoading} observerTarget={observerTarget} list={eventList ?? []} />
    </Box>
  );
};

export default UpdatesTab;
