import { Stack, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTheme } from '@emotion/react';
import EventCard from './EventCard';
import NoEventsAvailable from '../../NoEventsAvailable';

const EventList = ({ list, ignore, verify }) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Stack
      display="block"
      height="calc(100vh - 303px);"
      overflow="auto"
      p={desktop ? '0 32px' : '0 16px'}
    >
      {list?.map((event) => (
        <EventCard
          event={event}
          ignore={ignore}
          verify={verify}
        />
      ))}

      {(!list || (list && list.length === 0)) && (
      <NoEventsAvailable />
      )}
    </Stack>
  );
};

export default EventList;
