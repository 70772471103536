import React from 'react';
import {
  IconBlockAlt, IconKeyAlt, IconPowerAlt, IconTurnOffAlt, IconUnblockAlt,
} from '../../icons';

const RenderIcon = ({ type }) => {
  let component;

  switch (type) {
    case 'IconPower':
      component = (
        <IconPowerAlt />
      );
      break;

    case 'IconTurnOff':
      component = (
        <IconTurnOffAlt />
      );
      break;

    case 'IconUnblock':
      component = (
        <IconUnblockAlt />
      );
      break;

    case 'IconBlock':
      component = (
        <IconBlockAlt />
      );
      break;

    case 'IconKey':
      component = (
        <IconKeyAlt />
      );
      break;

    default: component = '';
      break;
  }

  return component;
};

export default RenderIcon;
