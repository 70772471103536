import {
  ToggleButton, styled,
} from '@mui/material';
import React from 'react';

const CustomButton = styled((props) => <ToggleButton {...props} />)((props) => {
  const showBorder = props?.showBorder ?? true;
  return {
    '&.MuiToggleButton-root': {
      margin: '0 !important',
      boxSizing: 'content-box',
      textTransform: 'none',
      borderRadius: '40px !important',
      border: showBorder ? '2px solid #E0E0E0 !important' : 'none !important',
      background: '#FFF',
      minWidth: '110px',
      textAlign: 'center',
      width: 'fit-content',
    },
    '&.Mui-selected':
    {
      boxSizing: 'content-box',
      border: showBorder ? '2px solid #8000FF !important' : 'none !important',
      background: '#FFF',

    },
  };
});

export default CustomButton;
