import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Alert } from '../../../resources/images/new_icons/alert.svg';
import { ReactComponent as Reports } from '../../../resources/images/new_icons/common-file-text 1.svg';
import { ReactComponent as Settings } from '../../../resources/images/new_icons/config.svg';
import { ReactComponent as Map } from '../../../resources/images/new_icons/pin-map 1.svg';
import Container from './Components/Container';
import BottomMenuItem from './Components/BottomMenuItem';
import { eventsSideBarActions, sidebarActions } from '../../../store';

const BottomMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState('map');
  const showToolbar = useSelector((state) => state.toolbar.isOpen);

  const handleSelection = (event, value) => {
    switch (value) {
      case 'map':
        navigate('/');
        setSelected('map');
        dispatch(sidebarActions.close());
        dispatch(eventsSideBarActions.close());
        break;
      case 'events':
        navigate('/');
        setSelected('events');
        dispatch(sidebarActions.close());
        dispatch(eventsSideBarActions.open('sideBar'));
        break;
      case 'reports':
        navigate('/reports/route');
        setSelected('reports');
        break;
      case 'settings':
        navigate('/settings/preferences');
        setSelected('settings');
        break;
      default:
        break;
    }
  };
  if (!showToolbar) return null;

  return (
    <Container direction="row" justifyContent="center" spacing="21px" width="calc(100% - 32px)" zIndex={8}>
      <BottomMenuItem isSelected={selected === 'map'} name="Mapa Geral" callback={(ev) => handleSelection(ev, 'map')}>
        <Map />
      </BottomMenuItem>

      <BottomMenuItem isSelected={selected === 'events'} name="Eventos" callback={(ev) => handleSelection(ev, 'events')}>
        <Alert />
      </BottomMenuItem>

      <BottomMenuItem isSelected={selected === 'reports'} name="Relatórios" callback={(ev) => handleSelection(ev, 'reports')}>
        <Reports />
      </BottomMenuItem>

      <BottomMenuItem isSelected={selected === 'settings'} name="Configurações" callback={(ev) => handleSelection(ev, 'settings')}>
        <Settings />
      </BottomMenuItem>

    </Container>
  );
};

export default BottomMenu;
