import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTheme } from '@emotion/react';
import moment from 'moment-timezone';
import AddressValue from '../../AddressValue';

import {
  IconArrowDown,
  IconEventNotification,
  IconEventNotificationRead,
} from '../../icons';

import eventNames from '../../../../resources/l10n/pt_BR.json';

const EventCard = ({ event, ignore, verify }) => {
  function capitalizeFirstLetter(str) {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  const [expand, setExpand] = useState(false);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const formatDate = (stringOriginal) => {
    console.log(stringOriginal);
    const data = moment.tz(stringOriginal, 'America/Sao_Paulo').format('D [de] MMMM [de] YYYY - HH:mm');
    console.log(data);

    return data;
  };

  const getAddress = async () => {
    if (!event.position) {
      const response = await fetch(`/api/positions?id=${event.positionId}`);
      if (response.ok) {
        const nPos = await response.json();
        event.position = nPos;
      }
    }
    setExpand((prev) => !prev);
  };

  return (
    <Stack
      mb="16px"
      width="100%"
      height={!expand ? (desktop ? '120px' : '146px') : '238px'}
      justifyContent="start"
      alignItems="start"
      direction="column"
      padding={desktop ? '16px' : '16px 16px 0'}
      sx={{ overflow: 'hidden', borderRadius: '12px', border: '1px solid #E0E0E0', transition: 'height 0.3s ease' }}
      overflow="hidden"

    >

      <Stack gap="12px" width="100%" height="133px">
        <Stack direction="row" gap="12px" position="relative">
          <Box
            sx={{
              display: 'flex',
              padding: '8px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              borderRadius: '40px',
              background: 'rgba(242, 153, 74, 0.10)',
              position: 'relative',
              width: 'max-content',
              height: 'max-content',
            }}
          >
            <IconEventNotificationRead
              style={{
                position: 'absolute',
                left: '1px',
                bottom: '24px',
              }}
            />
            <IconEventNotification
              style={{ color: '#F2994A', width: '16px', height: '16px' }}
            />
          </Box>

          <Stack justifyContent="center" direction="row" gap="12px">

            <Stack gap="4px">
              <Typography
                typography="event-header"
              >
                {event?.type === 'alarm' ? eventNames[`alarm${capitalizeFirstLetter(event?.attributes?.alarm)}`] : eventNames[`event${capitalizeFirstLetter(event?.type)}`] }

              </Typography>

              <Typography
                sx={{
                  color: '#828282',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                }}
              >
                { dayjs(event?.eventTime).fromNow() }
              </Typography>
            </Stack>
          </Stack>

          <IconButton
            sx={{
              padding: '0',
              width: '40px',
              height: '40px',
              position: 'absolute',
              right: '0',
              transform: 'translateY(-25%)',
            }}
            onClick={() => getAddress(event)}
          >
            <IconArrowDown
              style={{ transform: expand ? 'rotateX(180deg)' : null }}
            />
          </IconButton>
        </Stack>

        <Stack
          direction="row"
          padding={desktop ? '0 0 0 44px' : '0'}
          gap="16px"
        >
          <Button onClick={() => verify(event)} height="18px" variant="base">
            <Typography variant="button-text">Verifique a situação</Typography>
          </Button>
          <Button
            onClick={() => {
              ignore({ eventId: event.id, deviceId: event.deviceId });
            }}
            height="18px"
            variant="base"
          >
            <Typography variant="button-text">Desconsiderar</Typography>
          </Button>
        </Stack>
      </Stack>

      <Box paddingTop="20px" width="100%" height="110px">
        <Box width="100%">
          <Divider orientation="horizontal" />
        </Box>

        <Stack width="100%" padding="24px" gap="8px">
          <Typography width="270px" variant="button-text" color="#333">
            {event.position ? <AddressValue latitude={event.position[0].latitude} longitude={event.position[0].longitude} /> : 'Localização Inválida' }
          </Typography>

          <Typography variant="event-card-subtext">
            {formatDate(event.eventTime)}
          </Typography>
        </Stack>
      </Box>

    </Stack>
  );
};

export default EventCard;
