import {
  Box,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import { React, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';

import { eventsSideBarActions, sidebarActions } from '../../../store';
import CustomTabs from '../CustomTabs';
import CustomTab from '../CustomTab';

import LoaderContainer from '../LoaderContainer';
import DialogPageContainer from '../DialogPageContainer';
import DesktopHeader from '../DesktopHeader';
import MobileHeader from '../MobileHeader';
import TabPanel from '../TabPanel';
import CarTab from './Components/CarTab';
import DriversTab from './Components/DriversTab';
import UpdatesTab from './Components/UpdatesTab';

const CarCard = ({ isLoading, carInfo, positionInfo }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const [currentTab, setCurrentTab] = useState(0);

  const padding = desktop ? '28px' : '16px';

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <DialogPageContainer>
      {isLoading && (
        <LoaderContainer>
          <CircularProgress color="inherit" />
        </LoaderContainer>
      )}

      <Box position="relative" width="100%" padding={padding}>

        {desktop && (
        <DesktopHeader
          title="Detalhes do veículo"
          onClose={() => {
            dispatch(sidebarActions.close());
            dispatch(eventsSideBarActions.close());
          }}
        />
        )}
        {!desktop && <MobileHeader title="Detalhes do veículo" onClose={() => dispatch(sidebarActions.close())} />}

        <Box marginTop={desktop ? '32px' : '16px'}>
          <CustomTabs
            value={currentTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <CustomTab desktop={desktop.toString()} label="Veículo" {...a11yProps(0)} />
            <CustomTab desktop={desktop.toString()} label="Motoristas" {...a11yProps(1)} />
            <CustomTab desktop={desktop.toString()} label="Atualizações" {...a11yProps(2)} />
          </CustomTabs>
        </Box>

      </Box>

      <TabPanel style={{ width: '100%' }} noPadding index={0} value={currentTab}>
        <CarTab carInfo={carInfo} positionInfo={positionInfo} />
      </TabPanel>

      <TabPanel style={{ width: '100%' }} noPadding index={1} value={currentTab}>
        <DriversTab drivers={carInfo?.drivers || []} />
      </TabPanel>

      <TabPanel style={{ width: '100%' }} noPadding index={2} value={currentTab}>
        <UpdatesTab carInfo={carInfo} />
      </TabPanel>

    </DialogPageContainer>
  );
};

export default CarCard;
