import { Tab, styled } from '@mui/material';
import React from 'react';

const CustomTab = styled((props) => <Tab disableRipple {...props} />)((props) => {
  const isMobile = props.desktop === 'false';

  return {
    fontSize: isMobile ? '12px' : '14px',
    color: '#121212',
    lineHeight: '21px',
    textTransform: 'none',

    boxSizing: 'content-box',
    borderRadius: '40px',
    minHeight: '21px',
    width: 'auto',

    padding: isMobile ? '8px 0' : '8px 16px',

    display: 'flex',
    backgroundColor: '#F2F2F2',

    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s ease, color 0.3s ease',

    '&:hover': {
      background: 'white',
      color: '#8000FF',
    },
    '&.Mui-selected': {
      background: '#FFF',
      color: '#8000FF',
      border: 'none',
    },
  };
});

export default CustomTab;
