import * as React from 'react';
import {
  Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import StyledToggleButtonGroup from './CustomButtonGroup';
import CustomButton from './CustomButton';
import EventCount from '../../EventCount';
import { IconSelected, IconUnselected } from '../../icons';

const SeverityFilter = ({ filter, setFilter }) => {
  const devices = useSelector((state) => state.devices.items);
  const theme = useTheme();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const deviceArr = Object.values(devices);

  const totais = React.useMemo(() => {
    let Critico = 0;
    let Medio = 0;
    let Baixo = 0;

    deviceArr.forEach((objeto) => {
      const { eventCount } = objeto;

      Critico += eventCount?.Critico ?? 0;
      Medio += eventCount?.Medio ?? 0;
      Baixo += eventCount?.Baixo ?? 0;
    });

    return {
      Critico,
      Medio,
      Baixo,
    };
  }, [deviceArr]);

  const handleFormat = (event, newFormats) => {
    setFilter((prev) => {
      const newValue = { ...prev };
      newValue.severity = newFormats;
      return newValue;
    });
  };

  return (
    <StyledToggleButtonGroup
      value={filter.severity}
      onChange={handleFormat}
      aria-label="text formatting"
      flexDirection={desktop ? 'row' : 'column'}
    >
      <CustomButton showBorder={desktop} value="Baixo" aria-label="bold">
        <Stack alignItems="center" direction="row" gap={desktop ? '50px' : '15px'}>

          {!desktop && (
          <>
            {' '}
            {filter.severity.includes('Baixo') ? <IconSelected /> : <IconUnselected />}
          </>
          )}

          <Typography variant="button-text">Baixo</Typography>
          <EventCount width="28px" height="16px" severity="low">
            <Typography variant="event-count">{totais.Baixo}</Typography>
          </EventCount>
        </Stack>
      </CustomButton>
      <CustomButton showBorder={desktop} value="Medio" aria-label="italic">
        <Stack alignItems="center" direction="row" gap={desktop ? '50px' : '15px'}>
          {!desktop && (
          <>
            {' '}
            {filter.severity.includes('Medio') ? <IconSelected /> : <IconUnselected />}
          </>
          )}
          <Typography variant="button-text">Médio</Typography>
          <EventCount severity="medium">
            <Typography variant="event-count">{totais.Medio}</Typography>
          </EventCount>
        </Stack>
      </CustomButton>
      <CustomButton showBorder={desktop} value="Critico" aria-label="underlined">
        <Stack alignItems="center" direction="row" gap={desktop ? '50px' : '15px'}>
          {!desktop && (
          <>
            {' '}
            {filter.severity.includes('Critico') ? <IconSelected /> : <IconUnselected />}
          </>
          )}
          <Typography variant="button-text">Crítico</Typography>
          <EventCount severity="critical">
            <Typography variant="event-count">{totais?.Critico}</Typography>
          </EventCount>
        </Stack>
      </CustomButton>
    </StyledToggleButtonGroup>
  );
};

export default SeverityFilter;
