import {
  IconButton, LinearProgress, Paper, Stack,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { IconMinus, IconPlus } from '../../common/components/icons';

const useStyles = makeStyles(() => ({
  zoom: {
    position: 'absolute',
    top: '24px',
    right: '16px',
    padding: '16px',
    zIndex: 4,
    borderRadius: '16px',

    '& .MuiLinearProgress-root': {
      width: '8px',
      height: '227px',
      borderRadius: '30px',
      background: '#F2F2F2',
    },

    '& span.MuiLinearProgress-bar': {
      borderRadius: '30px',
      background: '#8000FF',
    },

    '& .MuiButtonBase-root': {
      padding: 0,
      height: '16px',
      width: '16px',
    },
  },
}));
const MapZoom = ({ zoomIn, zoomOut, currentZoom }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.zoom}>
      <Stack alignItems="center" spacing="16px">
        <IconButton type="button" onClick={zoomIn}>
          <IconPlus />
        </IconButton>
        <LinearProgress
          variant="determinate"
          value={currentZoom}
          sx={{
            '& .MuiLinearProgress-bar': {
              transform: `translateY(${100 - (currentZoom)}%)!important`,
            },
          }}
        />
        <IconButton type="button" onClick={zoomOut}>
          <IconMinus />
        </IconButton>
      </Stack>
    </Paper>
  );
};

export default MapZoom;
