import { grey } from '@mui/material/colors';
import { IconSelectArrow } from '../components/icons';

export default {
  MuiCircularProgress: {
    styleOverrides: {
      root: {
        '&.MuiCircularProgress-colorPrimary': {
          color: '#8000FF',
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: '#828282',

        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '21px',

        '&.Mui-checked .MuiSvgIcon-root': {
          color: '#8000FF',
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        fontFamily: 'Montserrat',
      },
    },
    variants: [
      {
        props: { variant: 'base' },
        style: {
          color: '#121212',
          fontFamily: 'Montserrat',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '24px',
        },
      },
      {
        props: { variant: 'logo' },
        style: {
          color: '#000',
          fontFamily: 'Montserrat',
          fontSize: '16px',
          fontWeight: 700,
        },
      },
      {
        props: { variant: 'nav-legend' },
        style: {
          color: '#121212',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        },
      },
      {
        props: { variant: 'button-text' },
        style: {
          color: '#121212',
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '18px',
          textTransform: 'none',
        },
      },
      {
        props: { variant: 'submit-button-text' },
        style: {
          color: '#FFF',
          fontFamily: 'Montserrat',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '21px', /* 150% */
        },
      },
      {
        props: { variant: 'event-card-subtext' },
        style: {
          color: '#828282',
          fontFamily: 'Montserrat',
          fontSize: 12,
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '18px', /* 150% */
        },
      },
      {
        props: { variant: 'event-modal' },
        style: {
          color: '#333',
          fontFamily: 'Montserrat',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        },
      },
      {
        props: { variant: 'event-header' },
        style: {
          color: '#333',
          fontFamily: 'Montserrat',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: 'normal',
        },
      }, {
        props: { variant: 'caption-gray' },
        style: {
          color: '#121212',

          textAlign: 'center',
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '18px',
        },
      },
      {
        props: { variant: 'event-count' },
        style: {
          color: '#FFF',
          textAlign: 'center',
          leadingTrim: 'both',
          textEdge: 'cap',
          fontFamily: 'Montserrat',
          fontSize: '10px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
        },
      },
      {
        props: { variant: 'tooltip' },
        style: {
          color: '#FFF',
          fontFamily: 'Montserrat',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '21px',
        },
      },
      {
        props: { variant: 'car-info-searchbar' },
        style: {
          color: '#333',
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '18px', /* 150% */

        },
      }, {
        props: { variant: 'tooltip-subtext' },
        style: {
          color: '#828282',
          fontFamily: 'Montserrat',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '21px', /* 150% */

        },
      }, {
        props: { variant: 'button-text' },
        style: {
          color: '#333',
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: 'normal',

        },
      }, {
        props: { variant: 'dialog-text' },
        style: {
          color: '#8000FF',
          textAlign: 'center',
          fontFamily: 'Montserrat',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '24px',
        },
      },
    ],
  },
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true,
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: grey[50],
        '& .MuiOutlinedInput-notchedOutline legend': {

          paddingRight: '5px',

        },
      },
    },
    variants: [
      {
        props: { variant: 'search' },
        style: {
          width: '667px',
          borderRadius: '70px',
          paddingLeft: 0,
          border: 0,
          background: '#F2F2F2',
          '& input': {
            padding: '16px 0 16px 24px',
          },
          '& input::placeholder': {
            color: '#828282',
            fontSize: '16px',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
        },
      },
      {
        props: { variant: 'search-mobile' },
        style: {
          width: '100%',
          borderRadius: '70px',
          paddingLeft: 0,
          border: '2px solid #E0E0E0',
          background: '#FFF',
          '& input': {
            padding: '16px 0 16px 24px',
          },
          '& input::placeholder': {
            color: '#828282',
            fontSize: '16px',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
          },
          '&.Mui-focused': {
            borderColor: '#8000FF',
            borderWidth: '2px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
        },
      },
    ],
  },
  MuiButton: {
    variants: [
      {
        props: { variant: 'base' },
        style: {
          borderRadius: '24px',
          background: '#F2F2F2',
          padding: '10px',
          alignItems: 'center',
          gap: '8px',
          whiteSpace: 'nowrap',
          border: '2px solid #F2F2F2',

          '&:hover': {
            color: '#8000FF',
            border: '2px solid #8000FF',
          },
        },
      },
      {
        props: { variant: 'route-menu' },
        style: {
          borderRadius: '24px',
          background: '#fff',
          padding: '8px 24px',
          alignItems: 'center',
          gap: '8px',
          whiteSpace: 'nowrap',
          border: '2px solid #F2F2F2',
          textTransform: 'none',

          '&.selected': {
            color: '#8000FF',
            border: '2px solid #8000FF',
          },

          '&:hover': {
            color: '#8000FF',
            border: '2px solid #8000FF',
          },
        },
      },
      {
        props: { variant: 'generic-menu' },
        style: {
          height: '24px',
          textTransform: 'none',
        },
      },
    ],
    styleOverrides: {
      sizeMedium: {
        height: '40px',
      },
    },
  },
  MuiMenu: {
    variants: [
      {
        props: { variant: 'generic-menu' },
        style: {
          '& .MuiPaper-root': {
            borderRadius: 16,
            border: '#F2F2F2',
            background: '#FFF',
          },
          '& .MuiMenuItem-root': {
            color: '#121212',

            display: 'flex',
            alignItems: 'center',
            gap: '16px',

            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '21px',

            '&.Mui-selected': {
              background: '#FFF',
            },
          },
        },
      },
    ],
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '700',
        color: '#121212',
        lineHeight: '300%',

        '&.MuiInputLabel-shrink': {
          transform: 'translate(15px, -17px) scale(0.9)',
        },
      },

    },
  },
  MuiSelect: {
    defaultProps: {
      IconComponent: IconSelectArrow,
    },
    styleOverrides: {
      root: {
        padding: '7.5px 10px',
        '& .MuiSelect-icon': {
          marginRight: '12px',
        },
        '& .MuiFormLabel-root': {
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: 'normal',
          color: 'yellow',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: '50px',
          borderColor: '#8000FF',
          borderWidth: '2px',
        },
      },
      sizeMedium: {
        height: '40px',
      },
    },
  },
  MuiIconButton: {
    variants: [
      {
        props: { variant: 'base' },
        style: {
          width: '48px',
          height: '48px',
          borderRadius: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '2px solid #F2F2F2',
          backgroundColor: '#F2F2F2',
          '&:hover': {
            color: '#8000FF',
            border: '2px solid #8000FF',
          },
        },
      },
      {
        props: { variant: 'sidebar' },
        style: {
          width: '100%',
          height: '48px',
          borderRadius: '0px',
          borderRight: '2px solid transparent',
          background: 'transparent',
          stroke: '#BDBDBD',
          '& svg': {
            width: '24px',
            height: '24px',
          },
          '&:hover': {
            color: '#8000FF',
            stroke: '#8000FF',
            borderRight: '2px solid #8000FF',
            background: 'transparent',
          },
          '&.selected': {
            color: '#8000FF',
            stroke: '#8000FF',
            borderRight: '2px solid #8000FF',
          },
        },
      },
      {
        props: { variant: 'bottom-menu' },
        style: {
          height: '48px',
          borderRadius: '0px',
          background: 'transparent',
          stroke: '#BDBDBD',
          '& svg': {
            width: '16px',
            height: '16px',
          },
          '&:hover': {
            color: '#8000FF',
            stroke: '#8000FF',
            background: 'transparent',
          },
          '&.selected': {
            color: '#8000FF',
            stroke: '#8000FF',
          },
        },
      },
      {
        props: { variant: 'report-button' },
        style: {
          borderRadius: '60px',
          background: '#F2F2F2',
          border: '2px solid #F2F2F2',
          padding: '12px 24px',
          transition: 'all 80ms ease-in-out',
          '&.Mui-disabled': {
            background: '#E0E0E0',
            border: '2px solid #E0E0E0',
            '& .MuiTypography-root': {
              color: '#828282',
            },
          },
          '&.active .MuiTypography-root, &.active svg path': {
            color: '#8000FF',
            stroke: '#8000FF',
          },
          '&.active': {
            border: '2px solid #8000FF',
          },
        },
      },
      {
        props: { variant: 'submit-button' },
        style: {
          padding: '12px 24px',
          borderRadius: '60px',
          background: '#8000FF',
          '&:hover': {
            background: '#6b01d5',
          },
          '&.Mui-disabled': {
            background: '#E0E0E0',
            '& .MuiTypography-root': {
              color: '#828282',
            },
          },

        },
      },
    ],
    styleOverrides: {
      sizeMedium: {
        height: '40px',
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiSnackbar: {
    defaultProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {

      root: {
        '& .MuiTableCell-root': {
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '18px',
          color: '#828282',
        },
        '& .MuiTableCell-root .MuiBox-root': {
          width: 'fit-content',
          background: '#F2F2F2',
          padding: '4px 8px 4px 8px',
          borderRadius: '4px',
        },
      },
    },
  },
  MuiTableBody: {
    styleOverrides: {

      root: {
        hover: {
          '&:hover': {
            backgroundColor: 'green !important',
          },
        },
        '& .MuiTableCell-root': {
          fontFamily: 'Montserrat',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '21px',
          color: 'black',
        },
        '& .MuiTableCell-root .MuiBox-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '6px 12px 6px 12px',
          borderRadius: '50px',
          width: '150px',
          border: '2px solid #F2F2F2',
          fontSize: '12px',
          textAlign: 'center',
          background: '#F2F2F2',
          marginRight: '10px',
          marginLeft: '15px',
          cursor: 'pointer',
        },
        '& .MuiTableCell-root .MuiBox-root:hover': {
          border: '2px solid #8000FF',
          background: 'white',
          '& svg path': {
            stroke: '#8000FF',
          },
          '& svg #fillPointer': {
            fill: '#8000FF',
          },
        },
        '& .MuiTableCell-root .MuiBox-root.active': {
          border: '2px solid #8000FF',
          background: 'white',
          '& svg path': {
            stroke: '#8000FF',
          },
          '& svg #fillPointer': {
            fill: '#8000FF',
          },
        },
        '& .MuiTableCell-root .MuiBox-root .MuiTypography-root': {
          color: 'black',
        },
        '& .MuiLink-root': {
          color: '#8000FF',
        },
        '& .MuiButtonBase-root': {
          color: 'black',
        },
      },
    },
  },
};
