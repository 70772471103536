import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React from 'react';

const useStyles = makeStyles(() => ({

  icon: {
    width: '30px',
    height: '30px',
    background: '#F7EEFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    marginBottom: '12px',
  },

  urgentIcon: {
    width: '30px',
    height: '30px',
    background: '#fdefef',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    marginBottom: '12px',
  },

  number: {
    color: '#333',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '16px',
    marginBottom: '10px',
  },

  text: {
    color: '#828282',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },
}));

const DeviceInfoItem = ({ icon, text, number, isUrgent }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={!isUrgent ? classes.icon : classes.urgentIcon}>
        {icon}
      </Box>
      <Typography className={classes.number}>
        {number ?? 0}
      </Typography>
      <Typography className={classes.text}>
        {text}
      </Typography>
    </Box>
  );
};

export default DeviceInfoItem;
