import {
  CircularProgress,
  Stack, useMediaQuery, useTheme,
} from '@mui/material';
import React from 'react';
import UpdateCard from './UpdateCard';
import LoaderContainer from '../../LoaderContainer';
import NoEventsAvailable from '../../NoEventsAvailable';

const UpdateList = ({ isLoading, list, observerTarget }) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Stack
      height={`calc(100vh - ${desktop ? '324px' : '268px'});`}
      overflow="auto"
      gap="12px"
      mt="16px"
      padding={`0 ${!desktop ? '16px' : '32px'} 0`}
    >
      {isLoading && (
      <LoaderContainer style={{ height: `calc(100vh - ${desktop ? '324px' : '268px'})` }}>
        <CircularProgress color="inherit" />
      </LoaderContainer>
      )}
      {list && list[0] && (
      <UpdateCard
        isFirst
        event={list[0]}
      />
      ) }
      {list?.map((event) => (
        <UpdateCard
          key={event.id}
          event={event}
        />
      ))}
      {list && list.length > 0 && (
      <div ref={observerTarget} />
      ) }
      {!list.length && !isLoading && <NoEventsAvailable isUpdate />}

    </Stack>
  );
};

export default UpdateList;
