import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from './components/EditItemView';
import EditAttributesAccordion from './components/EditAttributesAccordion';
import { useTranslation } from '../common/components/LocalizationProvider';
import SettingsMenu from './components/SettingsMenu';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const DriverPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();

  const defaultItem = { expirationTime: new Date() };

  const validate = () => item && item.name && item.uniqueId;

  return (
    <EditItemView
      endpoint="drivers"
      item={item}
      setItem={setItem}
      defaultItem={defaultItem}
      validate={validate}
      menu={<SettingsMenu />}
      breadcrumbs={['settingsTitle', 'sharedDriver']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedRequired')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ''}
                onChange={(event) => setItem({ ...item, name: event.target.value })}
                label={t('sharedName')}
              />
              <TextField
                value={item.uniqueId || ''}
                onChange={(event) => setItem({ ...item, uniqueId: event.target.value })}
                label={t('deviceIdentifier')}
              />
              <TextField
                value={item.cpf || ''}
                onChange={(event) => setItem({ ...item, cpf: event.target.value })}
                label="CPF"
              />
              <TextField
                value={item.rg || ''}
                onChange={(event) => setItem({ ...item, rg: event.target.value })}
                label="RG"
              />
              <TextField
                value={item.email || ''}
                onChange={(event) => setItem({ ...item, email: event.target.value })}
                label="E-mail"
              />
              <TextField
                value={item.emergencyContact || ''}
                onChange={(event) => setItem({ ...item, emergencyContact: event.target.value })}
                label="Emergency Contact"
              />
              <TextField
                value={item.cellphone || ''}
                onChange={(event) => setItem({ ...item, cellphone: event.target.value })}
                label="Cellphone"
              />
              <TextField
                value={item.address || ''}
                onChange={(event) => setItem({ ...item, address: event.target.value })}
                label="Address"
              />
              <TextField
                label="Expiration Time"
                type="date"
                value={item.expirationTime}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  setItem({ ...item, expirationTime: event.target.value });
                }}
              />
            </AccordionDetails>
          </Accordion>
          <EditAttributesAccordion
            attributes={item.attributes}
            setAttributes={(attributes) => setItem({ ...item, attributes })}
            definitions={{}}
          />
        </>
      )}
    </EditItemView>
  );
};

export default DriverPage;
