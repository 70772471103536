import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MapView from '../map/core/MapView';
import MapPadding from '../map/MapPadding';
import MapSelectedDevice from '../map/main/MapSelectedDevice';
import MapAccuracy from '../map/main/MapAccuracy';
import MapGeofence from '../map/MapGeofence';
import PoiMap from '../map/main/PoiMap';
import { devicesActions, eventsSideBarActions, sidebarActions } from '../store';
import MapDefaultCamera from '../map/main/MapDefaultCamera';
import MapLiveRoutes from '../map/main/MapLiveRoutes';
import MapPositions from '../map/MapPositions';
import MapOverlay from '../map/overlay/MapOverlay';

const MainMap = ({ filteredPositions, selectedPosition }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const onMarkerClick = useCallback((_, deviceId) => {
    dispatch(devicesActions.selectId(deviceId));

    if (typeof _ === 'number') {
      dispatch(sidebarActions.open());
      dispatch(eventsSideBarActions.close());
    }
  }, [dispatch]);

  return (
    <MapView>
      <MapOverlay />
      <MapGeofence />
      <MapAccuracy positions={filteredPositions} />
      <MapLiveRoutes />
      <MapPositions
        positions={filteredPositions}
        onClick={onMarkerClick}
        selectedPosition={selectedPosition}
        showStatus
      />
      <MapDefaultCamera />
      {desktop && (
        <MapPadding left={parseInt(theme.dimensions.drawerWidthDesktop, 10)} />
      )}
      <MapSelectedDevice />
      <PoiMap />
    </MapView>
  );
};

export default MainMap;
