import {
  Box, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import React from 'react';
import moment from 'moment';
import eventNames from '../../../../resources/l10n/pt_BR.json';

const UpdateCard = ({ event, isFirst = false }) => {
  const theme = useTheme();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  function capitalizeFirstLetter(str) {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const formatDate = (stringOriginal) => {
    const data = moment(stringOriginal).utc().format('D [de] MMMM [de] YYYY - hh:mm');

    return data;
  };
  return (
    <Stack
      direction="row"
      gap="6px"
      sx={{
        width: '100%',
        height: desktop ? '64px' : 'auto',
        borderRadius: '12px',
        border: '1px solid #E0E0E0',
        padding: '12px 0 12px 12px',
      }}
    >
      <Box sx={{
        width: '4px',
        height: '32px',
        borderRadius: '12px',
        background: '#CB96FF',
      }}
      />

      <Stack direction="column" gap="3px">
        <Typography sx={{
          color: '#333',
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '18px',
        }}
        >
          {isFirst ? 'Data da última atualização do veículo:' : event?.type === 'alarm' ? eventNames[`alarm${capitalizeFirstLetter(event?.attributes?.alarm)}`] : eventNames[`event${capitalizeFirstLetter(event?.type)}`]}

        </Typography>
        <Typography sx={{
          color: '#333',
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '18px', /* 150% */
        }}
        >
          {isFirst ? 'Última atualização' : 'atualização'}
          {' '}
          registrada em
          {' '}
          {formatDate(event.eventTime)}
          .
        </Typography>
      </Stack>
    </Stack>
  );
};

export default UpdateCard;
