import {
  Button, Menu, MenuItem,
} from '@mui/material';
import React, { useState } from 'react';
import {
  IconGenericMenuSelected, IconGenericMenuUnselected,
} from '../icons';

const GenericSelect = ({ children, value, onSelect, options = [] }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = ((event) => {
    setAnchorEl(event.currentTarget);
  });
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        variant="generic-menu"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {children}
      </Button>
      <Menu
        variant="generic-menu"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option) => (
          <MenuItem
            variant="generic-menu"
            key={option.value}
            selected={option.value === value}
            onClick={() => {
              onSelect(option.value);
              handleClose();
            }}
          >
            {(option.value === value && <IconGenericMenuSelected />) || <IconGenericMenuUnselected />}
            {option.label}
          </MenuItem>
        ))}
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </div>
  );
};

export default GenericSelect;
