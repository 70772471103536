import {
  Box, IconButton, Stack, Typography,
} from '@mui/material';
import React from 'react';

const BottomMenuItem = ({ callback, children, isSelected, name }) => (
  <Stack
    direction="row"
    alignItems="center"
    sx={{
      position: 'relative',
      '&::after': isSelected ? {
        content: '""',
        width: '24px',
        height: '2px',
        position: 'absolute',
        top: '0',
        left: 'calc(50% - 12px)',
        background: '#8000FF',
      } : {},
    }}
  >
    <IconButton
      variant="bottom-menu"
      className={isSelected ? 'selected' : ''}
      onClick={callback}
    >
      {children}
    </IconButton>
    <Box sx={{
      width: isSelected ? '100%' : '0px',
      overflow: 'hidden',
    }}
    >
      <Typography variant="nav-legend">
        {' '}
        {name}
        {' '}
      </Typography>
    </Box>
  </Stack>
);

export default BottomMenuItem;
