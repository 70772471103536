import { Tabs, styled } from '@mui/material';

const CustomTabs = styled(Tabs)({
  margin: 'auto',
  maxWidth: '408px',
  height: '53px',
  borderRadius: '38px',
  background: '#F2F2F2',

  '& .MuiTabs-flexContainer': {
    height: '53px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  '& .MuiTabs-scroller': {
    padding: '0 8px',
  },

  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabs-indicatorSpan': {
    display: 'none',
  },
});

export default CustomTabs;
