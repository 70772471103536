import React from 'react';
import {
  Box, Divider, Stack, useMediaQuery, useTheme,
} from '@mui/material';
import DialogPageContainer from '../DialogPageContainer';
import DesktopHeader from '../DesktopHeader';
import { AltIconSearch } from '../icons';
import CarItem from './Components/CarItem';

const SearchTab = ({ filteredDevices, setKeyword }) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <DialogPageContainer sx={desktop ? {} : { top: '150px' }}>

      <Box width="100%" overflow="hidden">
        {desktop && (
        <Box position="relative" width="100%" padding="28px">
          <DesktopHeader altIcon={<AltIconSearch />} title="Resultados da busca" onClose={() => setKeyword('')} />

        </Box>
        )}

        {desktop && (
          <Box marginBottom="30px" width="100%">
            <Divider orientation="horizontal" />
          </Box>
        ) }

        <Stack
          sx={{ overflowX: 'hidden' }}
          overflow="auto"
          p={desktop ? '0 24px' : '0 16px'}
          gap={desktop ? '30px' : '16px'}
          height={desktop ? 'calc(100vh - 225px)' : 'calc(100vh - 150px)'}
          paddingBottom="10px"
        >
          {filteredDevices.map((device) => <CarItem setKeyword={setKeyword} device={device} />)}
        </Stack>
      </Box>

    </DialogPageContainer>
  );
};

export default SearchTab;
