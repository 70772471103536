import React from 'react';
import { useTheme, useMediaQuery, Box } from '@mui/material';
import LogoNormal from '../assets/logo_normal.png';
import LogoWhite from '../assets/logo_white.png';

const LogoImage = () => {
  const theme = useTheme();

  const expanded = !useMediaQuery(theme.breakpoints.down('lg'));

  if (expanded) {
    return (
      <img
        alt="aa"
        style={{
          width: '238px', height: '110px', objectFit: 'cover',
        }}
        src={LogoWhite}
      />
    );
  }

  return (
    <Box display="flex" justifyContent="center">
      <img
        alt="aa"
        style={{
          width: '238px', height: '110px', objectFit: 'cover',
        }}
        src={LogoNormal}
      />
    </Box>
  );
};

export default LogoImage;
