import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'info',
  initialState: {
    criticalAlerts: 0,
    nearRevision: 0,
    onlineDevices: 0,
    totalAlerts: 0,
    totalDevices: 0,
  },
  reducers: {
    update(state, action) {
      state = action.payload;
      return state;
    },
  },
});

export { actions as infoActions };
export { reducer as infoReducer };
