import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'filterSideBar',
  initialState: { isOpen: false },
  reducers: {
    open(state) {
      state.isOpen = true;
    },
    close(state) {
      state.isOpen = false;
    },
  },
});

export { actions as filterSideBarActions };
export { reducer as filterSideBarReducer };
