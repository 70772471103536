import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { errorsReducer as errors } from './errors';
import { sessionReducer as session } from './session';
import { devicesReducer as devices } from './devices';
import { eventsReducer as events } from './events';
import { geofencesReducer as geofences } from './geofences';
import { groupsReducer as groups } from './groups';
import { driversReducer as drivers } from './drivers';
import { maintenancesReducer as maintenances } from './maintenances';
import { calendarsReducer as calendars } from './calendars';
import { reportsReducer as reports } from './reports';
import { sidebarReducer as sidebar } from './sidebar';
import { eventsSideBarReducer as eventsSideBar } from './eventsSideBar';
import { eventModalReducers as eventModal } from './eventModal';
import { eventDriverInfoReducers as eventDriverInfo } from './eventDriverInfo';
import { filterSideBarReducer as filterSideBar } from './filtersSideBar';
import { toolbarReducer as toolbar } from './toolbar';
import { infoReducer as info } from './info';

import throttleMiddleware from './throttleMiddleware';

const reducer = combineReducers({
  errors,
  session,
  devices,
  events,
  geofences,
  groups,
  drivers,
  maintenances,
  calendars,
  reports,
  sidebar,
  eventsSideBar,
  eventModal,
  eventDriverInfo,
  filterSideBar,
  toolbar,
  info,
});

export { errorsActions } from './errors';
export { sessionActions } from './session';
export { devicesActions } from './devices';
export { eventsActions } from './events';
export { geofencesActions } from './geofences';
export { groupsActions } from './groups';
export { driversActions } from './drivers';
export { maintenancesActions } from './maintenances';
export { calendarsActions } from './calendars';
export { reportsActions } from './reports';
export { sidebarActions } from './sidebar';
export { eventsSideBarActions } from './eventsSideBar';
export { eventModalActions } from './eventModal';
export { eventDriverInfoActions } from './eventDriverInfo';
export { filterSideBarActions } from './filtersSideBar';
export { toolbarActions } from './toolbar';
export { infoActions } from './info';

export default configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(throttleMiddleware),
});
