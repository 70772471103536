import * as React from 'react';
import { Typography, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import StyledToggleButtonGroup from './CustomButtonGroup';
import CustomButton from './CustomButton';
import { IconSelected, IconUnselected } from '../../icons';

const GroupFilter = ({ filter, setFilter }) => {
  const groups = useSelector((state) => state.groups.items);

  const handleFormat = (event, newFormats) => {
    setFilter((prev) => {
      const newValue = { ...prev };
      newValue.groups = newFormats;
      return newValue;
    });
  };

  return (
    <StyledToggleButtonGroup
      value={filter.groups}
      onChange={handleFormat}
      aria-label="text formatting"
    >

      {Object.keys(groups).map((item) => {
        const grupo = groups[item];

        return (
          <CustomButton sx={{ justifyContent: 'left', minWidth: '77px !important', background: 'red' }} value={grupo.id} aria-label="bold">
            <Stack direction="row" gap="8px">
              {filter.groups.includes(grupo.id) ? <IconSelected /> : <IconUnselected />}
              <Typography variant="button-text">{grupo.name}</Typography>
            </Stack>
          </CustomButton>
        );
      })}

    </StyledToggleButtonGroup>
  );
};

export default GroupFilter;
