import { Stack, Typography } from '@mui/material';
import React from 'react';

const DriverInfoField = ({ label, value }) => (
  <Stack borderBottom="1px solid #828282" pb="16px" spacing="4px">
    <Typography variant="event-card-subtext" fontWeight={600}>{label}</Typography>
    <Typography variant="button-text">{value ?? '-'}</Typography>
  </Stack>
);
export default DriverInfoField;
