import React from 'react';
import { Divider, Stack } from '@mui/material';
import { useSelector } from 'react-redux';

import DeviceInfoItem from './DeviceInfoItem';
import {
  Car, IconNearRevision, IconOnlineDevices, IconTotalAlerts, IconUrgentAlerts,
} from '../common/components/icons';

const DeviceInfoPanel = () => {
  const {
    totalDevices,
    onlineDevices,
    nearRevision,
    totalAlerts,
    criticalAlerts,
  } = useSelector((state) => state.info);

  return (
    <Stack direction="row" height="100%" justifyContent="space-evenly">
      <DeviceInfoItem icon={<Car />} number={totalDevices} text="Total de Veículos" />
      <Divider orientation="vertical" />
      <DeviceInfoItem icon={<IconOnlineDevices />} number={onlineDevices} text="Veiculos online" />
      <Divider orientation="vertical" />
      <DeviceInfoItem icon={<IconNearRevision />} number={nearRevision} text="Próximo da revisão" />
      <Divider orientation="vertical" />
      <DeviceInfoItem icon={<IconTotalAlerts />} number={totalAlerts} text="Alertas totais" />
      <Divider orientation="vertical" />
      <DeviceInfoItem icon={<IconUrgentAlerts />} number={criticalAlerts} text="Alertas urgentes" isUrgent />

    </Stack>
  );
};

export default DeviceInfoPanel;
