import { ToggleButtonGroup, styled } from '@mui/material';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)((props) => {
  const flexDirection = props?.flexDirection ?? 'row';

  return {
    gap: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection,
  };
});

export default StyledToggleButtonGroup;
