import {
  Box, CircularProgress, Dialog, Divider, IconButton, Paper, Stack, Typography, useMediaQuery,
} from '@mui/material';

import React, { useState } from 'react';
import { useTheme } from '@emotion/react';
import {
  IconCheckReport,
  IconClose,
  IconDownload, IconEmail, IconFile,
} from '../../common/components/icons';

const ReportDialog = ({ handleCloseDialog, isDialogOpen, handleClick = () => {}, loadingReport, sucess, setSucess }) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const [type, setType] = useState('');
  const close = () => {
    handleCloseDialog();
    setType('');
    setSucess(false);
  };

  return (

    <Dialog
      onClose={() => {
        close();
      }}
      open={isDialogOpen}
    >
      <Paper
        sx={{
          width: desktop ? '398px' : '100%',
          height: '451px',
          display: 'flex',
          alignItems: 'center',
          paddingTop: '32px',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Box sx={{ position: 'absolute', top: '0', right: '0' }}>
          <IconButton onClick={() => close()}>
            <IconClose />
          </IconButton>
        </Box>
        <Stack paddingBottom="24px" alignItems="center" gap="19px">
          <Box
            sx={{
              width: '48px',
              height: '48px',
              background: '#F5EBFF',
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconDownload />
          </Box>
          <Stack>
            <Typography
              sx={{
                color: '#121212',
                textAlign: 'center',
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '24px' /* 150% */,
              }}
            >
              Exportar Relatório
            </Typography>
            <Typography
              sx={{
                color: '#121212',
                textAlign: 'center',
                fontFamily: 'Montserrat',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '21px' /* 150% */,
              }}
            >
              Escolha a forma que deseja
            </Typography>
            <Typography
              sx={{
                color: '#121212',
                textAlign: 'center',
                fontFamily: 'Montserrat',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '21px' /* 150% */,
              }}
            >
              exportar o relatório
            </Typography>
          </Stack>
        </Stack>

        <Stack marginBottom="24px" gap="12px" px="24px" width="100%">
          <IconButton
            onClick={() => setType('mail')}
            sx={{
              width: '100%',
              height: '72px',
              borderRadius: '12px',
              border: `2px solid ${type === 'mail' ? '#8000FF' : '#E0E0E0'}`,
              background: 'white',

              '&:hover': {
                background: 'white',
                border: '2px solid #8000FF',
              },
            }}
          >
            <Stack width="100%" direction="row" gap="12px">

              <IconEmail active={type === 'mail'} />
              <Stack direction="column">

                <Typography
                  sx={{
                    color: '#121212',
                    fontFamily: 'Montserrat',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: '18px' /* 150% */,
                    textAlign: 'start',
                  }}
                >
                  Relatório por e-mail
                </Typography>
                <Typography
                  sx={{
                    color: '#828282',
                    fontFamily: 'Montserrat',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '18px', /* 150% */
                    textAlign: 'left',
                  }}
                >
                  Será enviado ao e-mail cadastrado.

                </Typography>
              </Stack>
            </Stack>
          </IconButton>
          <IconButton
            onClick={() => { setType('export'); }}
            sx={{
              width: '100%',
              height: '72px',
              borderRadius: '12px',
              border: `2px solid ${type === 'export' ? '#8000FF' : '#E0E0E0'}`,
              background: 'white',

              '&:hover': {
                background: 'white',
                border: '2px solid #8000FF',
              },
            }}
          >
            <Stack width="100%" direction="row" gap="12px">

              <IconFile active={type === 'export'} />
              <Stack direction="column">

                <Typography
                  sx={{
                    color: '#121212',
                    fontFamily: 'Montserrat',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: '18px' /* 150% */,
                    textAlign: 'start',
                  }}
                >
                  Download xlsx
                </Typography>
                <Typography
                  sx={{
                    color: '#828282',
                    fontFamily: 'Montserrat',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    textAlign: 'left',
                    lineHeight: '18px', /* 150% */
                  }}
                >
                  Arquivo no formato xlsx.

                </Typography>
              </Stack>
            </Stack>
          </IconButton>
        </Stack>

        <Box width="100%">
          <Divider orientation="horizontal" />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          padding="16px 23px"
          width="100%"
        >

          {!sucess && (

            loadingReport ? (
              <IconButton sx={{ backgroundColor: '#F0E0FF', '&:hover': { backgroundColor: '#F0E0FF' }, width: '140px' }} variant="submit-button">
                <Typography variant="submit-button-text">

                  <CircularProgress size={24} />

                </Typography>
              </IconButton>
            ) : (
              <IconButton sx={{ width: '140px' }} disabled={!type} onClick={() => handleClick(type)} variant="submit-button">
                <Typography variant="submit-button-text">
                  Download
                  {' '}
                </Typography>
              </IconButton>
            )

          )}
          {sucess && (
          <IconButton onClick={() => close()} sx={{ backgroundColor: '#27AE60', '&:hover': { backgroundColor: '#27AE60' }, width: '140px' }} variant="submit-button">

            <IconCheckReport />

          </IconButton>
          ) }
        </Box>
      </Paper>
    </Dialog>
  );
};

export default ReportDialog;
