import {
  styled, Stack, Button, Typography,
} from '@mui/material';
import React from 'react';
import EventCount from '../../EventCount';
import { IconChevronRight } from '../../icons';

const Container = styled(Button)`
    display: flex;
    width: 100%;
    padding: 12px;
    margin-top: 34px;
    align-items: center;
    gap: 16px;
    border-radius: 50px;
    justify-content: space-between;
    background: #F2F2F2;
`;

const MobileEventBar = ({ eventCount, ...rest }) => {
  const Critico = eventCount?.Critico || 0;
  const Medio = eventCount?.Medio || 0;
  const Baixo = eventCount?.Baixo || 0;

  return (
    <Container direction="row" {...rest}>
      <Stack direction="row" spacing="14px">
        <Typography variant="nav-legend" lineHeight="18px" textTransform="none">
          Eventos deste veículo
        </Typography>
        <EventCount severity="low">
          <Typography variant="event-count">
            {Baixo}
          </Typography>
        </EventCount>
        <EventCount severity="medium">
          <Typography variant="event-count">
            {Medio}
          </Typography>
        </EventCount>
        <EventCount severity="critical">
          <Typography variant="event-count">
            {Critico}
          </Typography>
        </EventCount>
      </Stack>

      <IconChevronRight />
    </Container>
  );
};

export default MobileEventBar;
