import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'eventDriverInfo',
  initialState: {
    isOpen: false,
    event: null,
  },
  reducers: {
    open(state, action) {
      state.isOpen = true;
      state.event = action.payload;
    },
    close(state) {
      state.isOpen = false;
      state.event = null;
    },
  },
});

export { actions as eventDriverInfoActions };
export { reducer as eventDriverInfoReducers };
