import React, { useRef } from 'react';
import {
  IconButton, OutlinedInput, InputAdornment, Badge, Typography, Stack, useMediaQuery, Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  IconChevronDown,
  IconLogo, IconSearch, IconTune, IconUser,
} from '../common/components/icons';
import {
  eventsSideBarActions, filterSideBarActions, sidebarActions,
} from '../store';

const MainToolbar = ({
  devicesOpen,
  setDevicesOpen,
  keyword,
  setKeyword,
  filter,
}) => {
  const dispatch = useDispatch();
  const showFiltersCard = useSelector((state) => state.filterSideBar.isOpen);
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const location = useLocation();

  const shouldShowDrawer = !location.pathname.includes('/reports');

  const inputRef = useRef();

  return (
    <Stack maxHeight={desktop ? '80px' : 'none'}>
      <Stack position="relative" direction="row" alignItems="center" justifyContent={!desktop ? 'space-between' : 'start'} width="100vw" padding="12px 16px" sx={{ border: '1px solid #E0E0E0' }} minHeight="81px">
        <Stack direction="row" alignItems="center" spacing="8px">
          <IconButton sx={{ height: '32px', width: '32px', padding: '0' }} onClick={() => setDevicesOpen(!devicesOpen)}>
            <IconLogo />
          </IconButton>
          <Typography variant="logo">Logigo</Typography>
        </Stack>

        {!desktop && (
          <Stack direction="row" alignItems="center" spacing="12px" padding="4px 16px 4px 4px" sx={{ border: '1px solid #E0E0E0', borderRadius: '30px' }}>
            <IconUser />
            <IconChevronDown />
          </Stack>
        )}

        {desktop && shouldShowDrawer && (
        <OutlinedInput
          ref={inputRef}
          placeholder="Procure por placa, chassi ou identificação do condutor"
          value={keyword}
          onChange={(e) => {
            setKeyword(e.target.value);
            dispatch(sidebarActions.close());
            dispatch(eventsSideBarActions.close());
          }}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  if (showFiltersCard) {
                    dispatch(filterSideBarActions.close());
                    return;
                  }
                  dispatch(filterSideBarActions.open());
                }}
                size="small"
                edge="end"
              >
                <Badge color="info" variant="dot" invisible={!filter?.statuses?.length && !filter?.groups?.length && !filter?.severity?.length}>
                  <IconTune />
                </Badge>
              </IconButton>
            </InputAdornment>
            )}
          startAdornment={(
            <InputAdornment position="end">
              <IconButton size="small" edge="end">
                <Badge color="info" variant="dot" invisible={!filter?.statuses?.length && !filter?.groups?.length && !filter?.severity?.length}>
                  <IconSearch />
                </Badge>
              </IconButton>
            </InputAdornment>
            )}
          size="small"
          variant="search"
          fullWidth
          sx={{ marginLeft: '48px', width: '667px' }}
        />

        )}
      </Stack>

      {!desktop && shouldShowDrawer && (
      <Box position="relative" width="100vw" padding="12px 16px">
        <OutlinedInput
          ref={inputRef}
          value={keyword}
          inputProps={{
            onBlur: () => {
              setTimeout(() => {
                setKeyword('');
              }, 200);
            },
          }}
          placeholder="Placa, chassi ou condutor"
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          endAdornment={(
            <InputAdornment position="end">
              {/* <IconButton size="small" edge="end">
                  <Badge color="info">
                    <IconClose />
                  </Badge>
                </IconButton> */}
              <IconButton onClick={() => dispatch(filterSideBarActions.open())} size="small" edge="end">
                <Badge color="info" variant="dot" invisible={!filter?.statuses?.length && !filter?.groups?.length}>
                  <IconTune />
                </Badge>
              </IconButton>
            </InputAdornment>
            )}
          startAdornment={(
            <InputAdornment position="end">
              <IconButton size="small" edge="end">
                <Badge color="info" variant="dot" invisible={!filter?.statuses?.length && !filter?.groups?.length}>
                  <IconSearch />
                </Badge>
              </IconButton>
            </InputAdornment>
            )}
          size="small"
          variant="search-mobile"
          fullWidth
        />
      </Box>

      )}
    </Stack>
  );
};

export default MainToolbar;
