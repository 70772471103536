import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  Divider,
  Stack,
  Popover,
  Grid,
  Checkbox,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from '../../common/components/LocalizationProvider';
import useReportStyles from '../common/useReportStyles';
import { devicesActions, reportsActions } from '../../store';
import SelectField from '../../common/components/SelectField';
import {
  IconExport,
  IconThreeColumns,
} from '../../common/components/icons';
import ReportDialog from './ReportDialog';

const ReportFilter = ({
  children,
  handleSubmit,
  handleSchedule,
  showOnly,
  ignoreDevice,
  multiDevice,
  includeGroups,
  columns,
  setColumns,
  columnsArray = [],
  rawValues = false,
  loadingReport = false,
  ColumnsDisabled = false,
  sucess = false,
  setSucess = () => null,
}) => {
  const classes = useReportStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);
  const groups = useSelector((state) => state.groups.items);

  const deviceId = useSelector((state) => state.devices.selectedId);
  const deviceIds = useSelector((state) => state.devices.selectedIds);
  const groupIds = useSelector((state) => state.reports.groupIds);
  const period = useSelector((state) => state.reports.period);
  const from = useSelector((state) => state.reports.from);
  const to = useSelector((state) => state.reports.to);
  // eslint-disable-next-line no-unused-vars
  const [button, setButton] = useState('json');

  const [description, setDescription] = useState();
  const [calendarId, setCalendarId] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDialogOpen, setIsDialongOpen] = useState(false);

  const handleClickAnchor = () => {
    const anchor = document.getElementById('iconAnchor');

    setAnchorEl(anchor);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseDialog = () => {
    setIsDialongOpen(false);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const scheduleDisabled =
    button === 'schedule' && (!description || !calendarId);
  const disabled =
    (!ignoreDevice && !deviceId && !deviceIds.length && !groupIds.length) ||
    scheduleDisabled;

  const handleClick = (type) => {
    // mail export
    if (type === 'schedule') {
      handleSchedule(deviceIds, groupIds, {
        description,
        calendarId,
        attributes: {},
      });
    } else {
      let selectedFrom;
      let selectedTo;
      switch (period) {
        case 'today':
          selectedFrom = moment().startOf('day');
          selectedTo = moment().endOf('day');
          break;
        case 'yesterday':
          selectedFrom = moment().subtract(1, 'day').startOf('day');
          selectedTo = moment().subtract(1, 'day').endOf('day');
          break;
        case 'thisWeek':
          selectedFrom = moment().startOf('week');
          selectedTo = moment().endOf('week');
          break;
        case 'previousWeek':
          selectedFrom = moment().subtract(1, 'week').startOf('week');
          selectedTo = moment().subtract(1, 'week').endOf('week');
          break;
        case 'thisMonth':
          selectedFrom = moment().startOf('month');
          selectedTo = moment().endOf('month');
          break;
        case 'previousMonth':
          selectedFrom = moment().subtract(1, 'month').startOf('month');
          selectedTo = moment().subtract(1, 'month').endOf('month');
          break;
        default:
          selectedFrom = moment(from, moment.HTML5_FMT.DATETIME_LOCAL);
          selectedTo = moment(to, moment.HTML5_FMT.DATETIME_LOCAL);
          break;
      }

      handleSubmit({
        deviceId,
        deviceIds,
        groupIds,
        from: selectedFrom.toISOString(),
        to: selectedTo.toISOString(),
        calendarId,
        type,
      });
    }
  };

  const handleCheck = (check, key) => {
    const copyArr = [...columns];

    if (check) {
      copyArr.push(key);
      setColumns([...copyArr]);
      return;
    }
    const index = copyArr.findIndex((item) => item === key);
    copyArr.splice(index, 1);
    setColumns([...copyArr]);
  };

  return (
    <>
      <ReportDialog sucess={sucess} setSucess={setSucess} loadingReport={loadingReport} handleClick={handleClick} handleCloseDialog={handleCloseDialog} isDialogOpen={isDialogOpen} />
      <Divider />
      <div className={classes.filter}>
        {!ignoreDevice && (
          <div className={classes.filterItem}>
            <FormControl fullWidth>
              <InputLabel>
                {t(multiDevice ? 'deviceTitle' : 'reportDevice')}
              </InputLabel>
              <Select
                label={t(multiDevice ? 'deviceTitle' : 'reportDevice')}
                value={multiDevice ? deviceIds : deviceId || ''}
                onChange={(e) => dispatch(
                  multiDevice
                    ? devicesActions.selectIds(e.target.value)
                    : devicesActions.selectId(e.target.value),
                )}
                multiple={multiDevice}
              >
                {Object.values(devices)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((device) => (
                    <MenuItem key={device.id} value={device.id}>
                      {device.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        )}
        {includeGroups && (
          <div className={classes.filterItem}>
            <FormControl fullWidth>
              <InputLabel>{t('settingsGroups')}</InputLabel>
              <Select
                label={t('settingsGroups')}
                value={groupIds}
                onChange={(e) => dispatch(reportsActions.updateGroupIds(e.target.value))}
                multiple
              >
                {Object.values(groups)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        )}
        {button !== 'schedule' ? (
          <>
            <div className={classes.filterItem}>
              <FormControl fullWidth>
                <InputLabel>{t('reportPeriod')}</InputLabel>
                <Select
                  label={t('reportPeriod')}
                  value={period}
                  onChange={(e) => dispatch(reportsActions.updatePeriod(e.target.value))}
                >
                  <MenuItem value="today">{t('reportToday')}</MenuItem>
                  <MenuItem value="yesterday">{t('reportYesterday')}</MenuItem>
                  <MenuItem value="thisWeek">{t('reportThisWeek')}</MenuItem>
                  <MenuItem value="previousWeek">
                    {t('reportPreviousWeek')}
                  </MenuItem>
                  <MenuItem value="thisMonth">{t('reportThisMonth')}</MenuItem>
                  <MenuItem value="previousMonth">
                    {t('reportPreviousMonth')}
                  </MenuItem>
                  <MenuItem value="custom">{t('reportCustom')}</MenuItem>
                </Select>
              </FormControl>
            </div>
            {period === 'custom' && (
              <div className={classes.filterItem}>
                <TextField
                  label={t('reportFrom')}
                  type="datetime-local"
                  value={from}
                  onChange={(e) => dispatch(reportsActions.updateFrom(e.target.value))}
                  fullWidth
                />
              </div>
            )}
            {period === 'custom' && (
              <div className={classes.filterItem}>
                <TextField
                  label={t('reportTo')}
                  type="datetime-local"
                  value={to}
                  onChange={(e) => dispatch(reportsActions.updateTo(e.target.value))}
                  fullWidth
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className={classes.filterItem}>
              <TextField
                value={description || ''}
                onChange={(event) => setDescription(event.target.value)}
                label={t('sharedDescription')}
                fullWidth
              />
            </div>
            <div className={classes.filterItem}>
              <SelectField
                value={calendarId || 0}
                onChange={(event) => setCalendarId(Number(event.target.value))}
                endpoint="/api/calendars"
                label={t('sharedCalendar')}
                fullWidth
              />
            </div>
          </>
        )}
        {children}
        <IconButton
          disabled={disabled}
          onClick={() => handleClick()}
          variant="submit-button"
        >
          <Typography variant="submit-button-text">Gerar relatório</Typography>
        </IconButton>

      </div>
      {!showOnly && (
        <>

          <Divider />
          <div className={classes.filter}>
            <div className={classes.filterItem}>
              {/*  <IconButton variant="report-button">
                <Stack gap="12px" alignItems="center" direction="row">
                  <IconCalendar />
                  <Typography color="#121212" variant="button-text">
                    Agendar e Visualizar Relatórios
                  </Typography>
                  {' '}
                </Stack>
              </IconButton> */}
            </div>
            <Stack direction="row">
              <Stack paddingRight="40px" direction="row" gap="16px">
                <IconButton
                  onClick={() => setIsDialongOpen(true)}
                  variant="report-button"
                  disabled={disabled}
                >
                  <Stack gap="12px" alignItems="center" direction="row">
                    <IconExport />
                    <Typography color="#121212" variant="button-text">
                      Exportar
                    </Typography>
                    {' '}
                  </Stack>
                </IconButton>
                {/* <IconButton sx={{ padding: '12px' }} variant="report-button">
                  <IconSearchReport />
                </IconButton> */}
                <IconButton
                  id="iconAnchor"
                  onClick={(e) => handleClickAnchor(e)}
                  variant="report-button"
                  className={anchorEl ? 'active' : ''}
                  disabled={ColumnsDisabled}
                >
                  <Stack gap="12px" alignItems="center" direction="row">
                    <IconThreeColumns />
                    <Typography color="#121212" variant="button-text">
                      Colunas
                    </Typography>
                    {' '}
                  </Stack>
                </IconButton>
                <Popover
                  id={id}
                  open={open && !!columnsArray.length}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      borderRadius: '16px',
                      border: '1px solid #E0E0E0',
                      background: '#FFF',

                      filter: 'drop-shadow(0px 12px 16px rgba(0, 0, 0, 0.08))',

                      transform: 'translateY(24px) !important',
                    },
                  }}
                >
                  {columnsArray.length && (
                  <Box
                    sx={{
                      width: '464px',
                      height: 'auto',
                      padding: '40px',

                    }}
                  >
                    <Grid container spacing="12px">
                      {columnsArray.map(([key, string]) => (
                        <Grid key={key} item xs={6}>
                          <Checkbox
                            sx={{ padding: '0px', paddingRight: '16px' }}
                            label="teste"
                            checked={columns.includes(key)}
                            onChange={(event, b) => {
                              handleCheck(b, key);
                            }}
                            inputProps={{ 'aria-label': 'teste' }}
                          />
                          {rawValues ? string : t(string)}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  )}
                </Popover>
              </Stack>
              {/* <Stack direction="row" gap="12px">
                <IconButton sx={{ padding: '12px' }} variant="report-button">
                  <IconBackArrow />
                </IconButton>
                <IconButton sx={{ padding: '12px' }} variant="report-button">
                  <IconFrontArrow />
                </IconButton>
              </Stack> */}
            </Stack>
          </div>
        </>
      )}

    </>

  );
};

export default ReportFilter;
