import { Stack, Typography } from '@mui/material';
import React from 'react';
import { IconNoEventsAvailable } from './icons';

const NoEventsAvailable = ({ isUpdate = false }) => (
  <Stack alignItems="center" justifyContent="center" height="80%">
    <IconNoEventsAvailable />
    <Typography width="220px" textAlign="center">
      No momento, não há
      {isUpdate ? ' atualizações' : ' eventos'}
      {' '}
      disponíveis.
    </Typography>
  </Stack>
);

export default NoEventsAvailable;
