import { Paper, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';

const DialogPageContainer = ({ children, zIndex, sx = {} }) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const width = !desktop ? '100vw' : '546px';
  const height = !desktop ? '100vh' : '100%';
  return (
    <Paper
      sx={{
        ...sx,
        width,
        height,
        pointerEvents: 'auto',
        zIndex: zIndex ?? 4,
        position: desktop ? 'relative' : 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        borderRadius: '0',
        overflowY: 'auto',
        paddingBottom: desktop ? '12px' : '80px',
      }}
    >
      {children}
    </Paper>
  );
};
export default DialogPageContainer;
