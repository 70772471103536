import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import ReportMenu from '../common/components/ReportMenu/ReportMenu';

const ReportContainer = () => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  return (

    <div style={desktop ? { height: 'calc(100vh - 178px)' } : { height: '100%', width: '100%' }}>
      <ReportMenu />

      <Outlet />
    </div>

  );
};

export default ReportContainer;
