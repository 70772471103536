import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';

const EventButtonContainer = ({ icon, text, callback = () => null }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    gap="12px"
    width="70px"
    height="78px"
    flexShrink={0}
  >
    <IconButton onClick={() => { callback(); }} variant="base">
      {icon}
    </IconButton>

    <Typography fontSize="12px" lineHeight="18px" variant="base">
      {text}
    </Typography>
  </Box>
);

export default EventButtonContainer;
